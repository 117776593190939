import { Row, Col, Card, Button,Form,Input, Skeleton, Image, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from "antd";
import { shortLang, longLang } from "../../config/language";
import moment from "moment";
import notfound from "../../assets/images/not_found.png";
import pdffile from "../../assets/images/pdf-file.png";
const endpoint= "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/invent-colab-obj-bucket/";

function View() {
  const sectionName = "Appointment";
  const routeName = "appointments";
  const navigate = useNavigate();
  const params = useParams();
  const { request } = useRequest();
  const [list, setList] = useState({});
  const [cart, setCart] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const fetchData = (id) => {
    request({
      url: apiPath.viewAppointment + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data[0]);
        setCart(data.getCart);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData(params.id);
  }, []);

  return (
    <>
      <Card className="appointment-h" title={sectionName + " Details"}>
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="view-main-list">
                <div className="view-inner-cls">
                  <h5>Appointment Id:</h5>
                  <h6 className="cap">{list?.appointment_id || "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Doctor:</h5>
                  <h6 className="cap">
                    <Image
                      className="imagefix"
                      src={list?.doctor?.image || notfound}
                    />
                    <Link to={`/doctor/view/${list?.doctor?._id}`}>
                      {list?.doctor?.name || "-"}
                    </Link>
                  </h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Patient:</h5>
                  <h6 className="cap">
                    <Image
                      className="imagefix"
                      src={list?.patient_details?.image || notfound}
                    />
                    <Link to={`/patient/view/${list?.patient_details?._id}`}>
                      {list?.patient_details?.name || "-"}
                    </Link>
                  </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Charges:</h5>
                  <h6 className="cap">${list?.price || "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Date:</h5>
                  <h6 className="cap">
                    {list?.appointment_date
                      ? moment(list?.appointment_date).format("DD-MMM-YYYY")
                      : "-"}
                  </h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Time:</h5>
                  <h6 className="cap">{list?.appointment_time || "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Type:</h5>
                  <h6 className="cap">{list?.appointment_type || "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Status:</h5>
                  <h6 className="cap">{list?.appointment_status || "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Category:</h5>
                  <h6 className="cap">{list?.appointment_category || "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Current Status:</h5>
                  <h6 className="cap">{list?.status || "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Brief of Health Complaint:</h5>
                  <h6 className="cap">{list?.description ? list?.description : "-"}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Created On:</h5>
                  <h6>
                    {list?.created_at
                      ? moment(list?.created_at).format("DD-MMM-YYYY")
                      : "-"}
                  </h6>
                </div>

                <div className="pdf-view">
                  <div className="view-inner-view">
                    <h5>Precription:</h5>
                    {list?.precriptionPdf ? (
                      <>
                        <div className="pdf-icons">
                          {" "}
                          <i className="fas fa-file-pdf"></i>
                        </div>
                        <h6>
                          <a
                            href={endpoint + list?.precriptionPdf}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Precription
                          </a>
                        </h6>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>

                  <div className="view-inner-view">
                    <h5>Advisory Notes:</h5>
                    {list?.advisoryNotes ? (
                      <>
                        <div className="pdf-icons">
                          {" "}
                          <i className="far fa-file"></i>
                        </div>
                        <h6>
                          <a
                            href={endpoint + list?.advisoryNotes}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Notes
                          </a>
                        </h6>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="view-inner-view">
                    <h5>Lab Reports:</h5>
                    {list?.labReports ? (
                      <>
                        {" "}
                        <div className="pdf-icons">
                          {" "}
                          <i className="far fa-file-alt"></i>{" "}
                        </div>
                        <h6>
                          <a
                            href={endpoint + list?.labReports}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Lab Reports
                          </a>
                        </h6>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>

                  <div className="view-inner-view">
                    <h5>Patient documents:</h5>

                    {list?.otherDoc?.length > 0
                      ? list?.otherDoc?.map((doc) => (
                          <h6>
                            <a
                              href={endpoint + doc}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="pdf-icons">
                                {" "}
                                <i className="far fa-file-alt"></i>{" "}
                              </div>
                            </a>
                          </h6>
                        ))
                      : "-"}
                    <div></div>
                  </div>
                </div>

                <Divider />
                {list?.followUp && (
                  <div className="Case-main-list mb-3">
                  <Card>
                           <div className="main-follow-up-img">
                             <div className="ant-card-head">
                               <div className="ant-card-head-title">
                                 Follow-up form
                               </div>
                             </div>
                             {/* <Collapse defaultActiveKey={['1']} expandIconPosition="right" > */}
                             <Form
                               key={"formKey"}
                               id="create"
                               form={form}
                               layout="vertical"
                               disabled
                               initialValues={{...list?.followUp}}
                             >
                               <Row gutter={[48, 12]} className="pt-3 m-0">
                                 <Col span={24} md={12}>
                                   <Form.Item label="C/o" name="c_o">
                                     <Input placeholder="C/o" />
                                   </Form.Item>
                                 </Col>

                                 <Col span={24} md={12}>
                                   <Form.Item label="Nadi" name="nadi">
                                     <Input placeholder="Nadi" />
                                   </Form.Item>
                                 </Col>

                                 <Col span={24} md={12}>
                                   <Form.Item label="Mala" name="mala">
                                     <Input placeholder="Mala" />
                                   </Form.Item>
                                 </Col>

                                 <Col span={24} md={12}>
                                   <Form.Item label="Mutra" name="mutra">
                                     <Input placeholder="Mutra" />
                                   </Form.Item>
                                 </Col>

                                 <Col span={24} md={12}>
                                   <Form.Item label="Nidra" name="nidra">
                                     <Input placeholder="Nidra" />
                                   </Form.Item>
                                 </Col>

                                 <Col span={24} md={12}>
                                   <Form.Item
                                     label="Menstrual History"
                                     name="menstrual_history"
                                   >
                                     <Input placeholder="Menstrual History" />
                                   </Form.Item>
                                 </Col>

                                 <Col span={24} md={12}>
                                   <Form.Item
                                     label="Other Findings"
                                     name="other_findings"
                                   >
                                     <Input placeholder="Other Findings" />
                                   </Form.Item>
                                 </Col>

                                 <Col span={24} md={12}>
                                   <Form.Item
                                     label="Investigation"
                                     name="investigation"
                                   >
                                     <Input placeholder="Investigation" />
                                   </Form.Item>
                                 </Col>

                                 <Col span={24} md={12}>
                                   <Form.Item
                                     label="Treatment"
                                     name="treatment"
                                   >
                                     <Input placeholder="Treatment" />
                                   </Form.Item>
                                 </Col>
                               </Row>
                             </Form>
                           </div>
                         </Card>
               </div>
                )}
                {/* <h5>Cart Details:</h5>

                <>
                  <div className="view-inner-cls">
                    <h5>Cart Status:</h5>
                    <h6 className="cap">{cart?.cartStatus || '-'}</h6>
                  </div>
                  {cart?.products && cart?.products.length > 0 ? (
                    cart?.products?.map((product, index) => (
                      <div key={index} className="view-inner-cls">
                        <h5>{product?.name}:</h5>
                        <h6 className="cap">
                          <Image className="imagefix" src={product?.image || notfound} />
                          {product?.generic_name || '-'}
                        </h6>
                        <h6 className="cap">Price: ${product?.price}</h6>
                        <h6 className="cap">Quantity: {product?.quantity}</h6>
                        <h6 className="cap">Unit: {product?.unit}</h6>
                      </div>
                    ))
                  ) : (
                    <div className="view-inner-cls">
                      <h6>No cart data available</h6>
                    </div>
                  )}
                </> */}

                <div className="view-inner-cls float-right">
                  <Link
                    className="ant-btn ant-btn-primary"
                    to={""}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Link>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default View;
