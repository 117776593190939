import {
  Card,
  Col,
  Progress,
  Tooltip,
  Button,
  Radio,
  Row,
  Image,
  Select,
  Skeleton,
  Tabs,
  Avatar,
  Tag,
  Table,
  Typography,
} from "antd";
import SectionWrapper from "../../components/SectionWrapper";

import React, { useContext, useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import LineChartWithoutAxis from "./_Line";
import LineChart from "./_LineCart";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  AuthContext,
  AuthProvider,
  useAuthContext,
} from "../../context/AuthContext";
import moment from "moment";
import ConfirmationBox from "../../components/ConfirmationBox";
const { TabPane } = Tabs;
const { Title, Text } = Typography;

function Home() {
  const { setPageHeading, country } = useContext(AppStateContext);
  const { userProfile } = useAuthContext();
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState();
  const { showConfirm } = ConfirmationBox();
  const [dashboard, setDashboard] = useState();
  const [todayAppointments, setTodayAppointments] = useState();
  const [topDoctor, settopDoctor] = useState();
  const [revenue, setRevenue] = useState();
  const [period, setPeriod] = useState("month");
  const [selectedIds, setSelectedIds] = useState([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [list, setList] = useState([]);
  const [upcomingData, setUpcomingData] = useState({});

  const navigate = useNavigate();

  const api = {
    appointment: apiPath.appointment,
    order: apiPath.order,
  };

  useEffect(() => {
    setPageHeading(`Welcome Dr. ${userProfile?.name}`);
  });
  const count = [
    {
      today: "Today's  Appointments",
      title: `${
        dashboard?.totalTodaysAppointments
          ? dashboard.totalTodaysAppointments
          : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: "/appointment?appointment_status=today",
      key: "totalAppointments",
    },
    {
      today: "Future Appointments",
      title: `${
        dashboard?.totalUpcomingAppointmnets
          ? dashboard.totalUpcomingAppointmnets
          : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: "/appointment?appointment_status=future",
      key: "totalAppointments",
    },
    {
      today: "Completed  Appointments",
      title: `${
        dashboard?.totalCompletedAppointments
          ? dashboard.totalCompletedAppointments
          : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: "/appointment?appointment_status=past",
      key: "totalAppointments",
    },
    {
      today: "My Patients",
      title: `${dashboard?.totalPatients ? dashboard.totalPatients : 0}`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: "/patient",
      key: "totalAppointments",
    },
  ];

  const appointments = [
    {
      title: "Appointment ID",
      dataIndex: "appointment_id",
      key: "appointment_id",
      render: (_, { appointment_id }) =>
        appointment_id ? <span className="cap">{appointment_id}</span> : "-",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (_, { _id, user_id }) => {
        return (
          <>
            {user_id ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <span className="cap">{user_id?.name}</span>
                {user_id?.mobile_number && user_id?.country_code && (
                  <span style={{ color: "gray", fontSize: "12px" }}>
                    {"" + user_id?.country_code + "-" + user_id?.mobile_number}
                  </span>
                )}
                <span style={{ color: "gray", fontSize: "12px" }}>
                  {user_id?.email}
                </span>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, { price }) => {
        return price ? <span className="cap">${price}</span> : "-";
      },
    },
    {
      title: "Patient",
      dataIndex: "patient",
      key: "patient",
      render: (_, { patient_id, booked_for }) => {
        return (
          <>
            {patient_id ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <span className="cap">{patient_id?.name}</span>
                {patient_id?.mobile_number && patient_id?.country_code && (
                  <span style={{ color: "gray", fontSize: "12px" }}>
                    {patient_id?.country_code + "-" + patient_id?.mobile_number}
                  </span>
                )}
                <span style={{ color: "gray", fontSize: "12px" }}>
                  {patient_id?.email}
                </span>
                {booked_for && (
                  <Tag
                    color={
                      booked_for == "self"
                        ? "green"
                        : booked_for == "relation"
                        ? "blue"
                        : "teal"
                    }
                    key={booked_for}
                    className="cap"
                  >
                    {booked_for === "relation"
                      ? patient_id?.relationship_with_user
                      : booked_for}
                  </Tag>
                )}
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      key: "doctor",
      render: (_, { _id, doctor_id }) => {
        return (
          <>
            {doctor_id ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <span className="cap">{doctor_id?.name}</span>
                {doctor_id?.mobile_number && doctor_id?.country_code && (
                  <span style={{ color: "gray", fontSize: "12px" }}>
                    {doctor_id?.country_code + "-" + doctor_id?.mobile_number}
                  </span>
                )}
                <span style={{ color: "gray", fontSize: "12px" }}>
                  {doctor_id?.email}
                </span>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    {
      title: "Scheduled Time",
      key: "time",
      dataIndex: "time",
      render: (_, { appointment_date, appointment_time }) => {
        return moment.parseZone(appointment_date).format("DD-MMM-YYYY");
      },
    },

    {
      title: "Booked On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              color={"purple"}
              title={"View Appointment Manager"}
              key={"viewappointment" + "appointment"}
            >
              <Button
                className="btnStyle  primary_btn"
                title="View"
                onClick={() => navigate(`/appointment/view/${record._id}`)}
              >
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];
  const todayAppointment = [
    {
      title: "Appointment ID",
      dataIndex: "appointment_id",
      key: "appointment_id",
      render: (_, { appointment_id }) =>
        appointment_id ? <span className="cap">{appointment_id}</span> : "-",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (_, { _id, user_id }) => {
        return (
          <>
            {user_id ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <span className="cap">{user_id?.name}</span>
                {user_id?.mobile_number && user_id?.country_code && (
                  <span style={{ color: "gray", fontSize: "12px" }}>
                    {"" + user_id?.country_code + "-" + user_id?.mobile_number}
                  </span>
                )}
                <span style={{ color: "gray", fontSize: "12px" }}>
                  {user_id?.email}
                </span>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, { price }) => {
        return price ? <span className="cap">${price}</span> : "-";
      },
    },
    {
      title: "Patient",
      dataIndex: "patient",
      key: "patient",
      render: (_, { patient_id, booked_for }) => {
        return (
          <>
            {patient_id ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <span className="cap">{patient_id?.name}</span>
                {patient_id?.mobile_number && patient_id?.country_code && (
                  <span style={{ color: "gray", fontSize: "12px" }}>
                    {patient_id?.country_code + "-" + patient_id?.mobile_number}
                  </span>
                )}
                <span style={{ color: "gray", fontSize: "12px" }}>
                  {patient_id?.email}
                </span>
                {booked_for && (
                  <Tag
                    color={
                      booked_for == "self"
                        ? "green"
                        : booked_for == "relation"
                        ? "blue"
                        : "teal"
                    }
                    key={booked_for}
                    className="cap"
                  >
                    {booked_for === "relation"
                      ? patient_id?.relationship_with_user
                      : booked_for}
                  </Tag>
                )}
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      key: "doctor",
      render: (_, { _id, doctor_id }) => {
        return (
          <>
            {doctor_id ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <span className="cap">{doctor_id?.name}</span>
                {doctor_id?.mobile_number && doctor_id?.country_code && (
                  <span style={{ color: "gray", fontSize: "12px" }}>
                    {doctor_id?.country_code + "-" + doctor_id?.mobile_number}
                  </span>
                )}
                <span style={{ color: "gray", fontSize: "12px" }}>
                  {doctor_id?.email}
                </span>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Scheduled Date",
      key: "date",
      dataIndex: "date",
      render: (_, { appointment_date }) => {
        return moment.parseZone(appointment_date).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Scheduled Time",
      key: "time",
      dataIndex: "time",
      render: (_, { appointment_date, appointment_time }) => {
        const dateTimeString = `${
          appointment_date.split("T")[0]
        }T${appointment_time}`;
        return moment(dateTimeString).format("hh:mm A");
      },
    },

    {
      title: "Booked On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              color={"purple"}
              title={"View Appointment Manager"}
              key={"viewappointment" + "appointment"}
            >
              <Button
                className="btnStyle  primary_btn"
                title="View"
                onClick={() => navigate(`/appointment/view/${record._id}`)}
              >
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const fetchData = async (period) => {
    setLoading(true);
    request({
      url: `admin/dashboard/graph?period=${period}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
        // setUpcomingAppointments(data.data.upcomingAppointments)
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const fetchUpcomingData = async () => {
    setLoading(true);
    request({
      url: `app/user/get-doctor-AppointType/today`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setUpcomingData(data?.data?.data?.[0] ?? {});
        // setUpcomingAppointments(data.data.upcomingAppointments)
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };
  useEffect(() => {
    fetchData(period);
    fetchUpcomingData();
  }, [period]);

  const onChange = (e) => {
    setPeriod(e.target.value);
  };

  const handleChange = (value) => {
    fetchData();
  };

  useEffect(() => {
    setLoading(true);
    request({
      url: apiPath.dashboard + `${year ? `?year=${year}` : ""}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setDashboard(data.data);
        setUpcomingAppointments(data.data.upcomingAppointments);
        setTodayAppointments(data.data.todaysAppointments);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, [year, country.country_id]);

  const correctAppTime = (appointment_time) => {
    const timeInLocal = moment.utc(appointment_time, "HH:mm").local();
    return (
      <p>{timeInLocal.isValid() ? timeInLocal.format("hh:mm A") : "-"}</p>
    );
  }

  return (
    <>
      <div className="page-top-space home-card layout-content">
        <div className="mb-24">
          <div className="sub_title">
            <p>Here is the information about all your business</p>
          </div>
          <SectionWrapper className="mb-3">
            <Row gutter={[24, 0]} className="ms-3 me-3 mt-3">
              <Col xs={24} sm={18} md={24}>
                <Row
                  className=" mb-3"
                  gutter={[24, 16]}
                  // style={{ marginLeft: "0" }}
                >
                  {count.map((c, index) => (
                    <Col
                      key={index}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      className="mb24"
                      // style={{ paddingRight: "0px" }}
                    >
                      {/* <Link to={c.url}> */}
                      <CountCard c={c} key={index} loading={loading} />
                      {/* </Link> */}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </SectionWrapper>
          <Card className="mb-3" title="Today Appointments" onClick={()=> navigate("/appointment?appointment_status=today")}>
            <div className="main-new-aol-new-456-hjk">
              <h4>
                <span>New </span> - {upcomingData?.appointment_id ? upcomingData?.appointment_id : ""}
              </h4>
              {/* <h4>
                <span>jan 17 , 2025 </span>- {correctAppTime(upcomingData?.appointment_time)}
              </h4> */}
            </div>
            <div className="card-data-user-image-image-main">
              <div className="card-data-user-image-image">
                <div className="card-data-user-image">
                  {upcomingData?.patient_id?.image ? (
                    <img src={upcomingData?.patient_id?.image} />
                  ) : (
                    <>
                      <Avatar
                        style={{
                          backgroundColor: "#00a2ae",
                          verticalAlign: "middle",
                        }}
                        className="cap"
                        size={60}
                      >
                        {" "}
                        {upcomingData?.patient_id?.name?.charAt(0)}{" "}
                      </Avatar>
                    </>
                  )}
                </div>
                <div className="card-data-user">
                  <h6>
                    UHID :{" "}
                    {upcomingData?.patient_id?.uhid
                      ? upcomingData?.patient_id?.uhid
                      : ""}
                  </h6>
                  <h3>
                    {upcomingData?.patient_id?.name
                      ? upcomingData?.patient_id?.name
                      : ""}
                  </h3>
                  <h3>
                    {upcomingData?.country?.name
                      ? upcomingData?.country?.name
                      : ""}
                  </h3>
                </div>
              </div>
              <div className="card-data-user-image-image-not">N/A</div>
            </div>
          </Card>
        </div>
        {/* <SectionWrapper cardHeading={`Today's Appointments`}>
          <div className="table-responsive customPagination ">
            <Table
              loading={loading}
              columns={todayAppointment}
              dataSource={todayAppointments}
              onChange={handleChange}
              className="ant-border-space for-scrollablee"
            />
          </div>
        </SectionWrapper> */}
      </div>
    </>
  );
}

const CountCard = ({ c, loading }) => {
  const [percentage, setPercentage] = useState();
  const [difference, setDifference] = useState();
  const { userProfile } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!c) return null;

    const diff = c._7Day - c._14Day;

    const percentage = parseInt(
      ((diff / (c._7Day + c._14Day)) * 100).toFixed(2)
    );

    setPercentage(!!percentage ? percentage : 0);
  }, [c]);

  //if (!c) return null
  return (
    <Card
      hoverable
      bordered={false}
      className="criclebox dash-b-circle"
      style={{ height: "100%" }}
      loading={loading}
      onClick={() => {
        userProfile?.type != "SubAdmin" && c.url && navigate(c.url);
      }}
    >
      <div className="number">
        <Row align="middle" gutter={[24, 0]}>
          <Col xs={18}>
            <span>{c?.today}</span>
            {/* <p className="ftp_text">Last 7 days</p> */}
            <Title level={3}>{c?.title}</Title>
          </Col>
          {/*   <Col xs={6}>
            <div className="icon_box">
              <LineChartWithoutAxis
                isUp={percentage >= 0}
                points={[c?._14Day, c?._7Day]}
              />
            </div>
          </Col> */}
        </Row>
      </div>
      {/* 
      <div className="number_main">
        <div
          className="icon"
          style={{ color: percentage <= 0 ? "red" : "green" }}
        >
          <span>
            {percentage > 0 ? (
              <i class="fas fa-arrow-up"></i>
            ) : (
              <i class="fas fa-arrow-down"></i>
            )}
          </span>
          <span className="percentage">{Math.abs(percentage)}%</span>
        </div> */}
      {/* <div className="days">
          <span>vs last 7 days</span>
        </div> */}
      {/* </div> */}
    </Card>
  );
};

export default Home;
