import { Button, Image, Switch, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import EditIcon from "../../assets/images/edit.svg";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddForm from "./AddForm";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";


function Index() {
  const heading = lang("Transaction") + " " + lang("management");
  const { setPageHeading, country } = useContext(AppStateContext);

  const sectionName = "Transaction";
  const routeName = "patient";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");

  const api = {
    patient: apiPath.listTransaction,
    addEdit: apiPath.listPatient,
  };


  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [deleteAllModal, showDeleteAllModal] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const activity = (id) => {
    navigate(`/user/activity/${id}`);
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectedIds(selectedRowKeys);
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User", // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };

  const handleChangeStatus = (id) => {
    request({
      url: api.status + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onDelete = (id) => {
    request({
      url: api.appointmentPrice + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const DeleteAll = () => {
    if (!selectedIds.length) return;
    request({
      url: api.appointmentPrice + "/delete-all",
      method: "POST",
      data: {
        ids: selectedIds,
      },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "uhid",
      key: "uhid",
      render: (_, { uhid, transaction_id }) => {
        return uhid ? (
          <Link to={`/${routeName}/view/${transaction_id}`}>{uhid}</Link>
        ) : (
          transaction_id
        );
      },
    },
    {
      title: "Transaction Date",
      key: "date_time_transaction",
      dataIndex: "date_time_transaction",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: "Appointment ID",
      dataIndex: ["appointment_id", "appointment_id"],
      key: "appointment_id",
      filterMultiple: false,
      width: 200,
      render: (_, { appointment_id }) => {
        return appointment_id && appointment_id.appointment_id 
          ? <Link to={`/appointment/view/${appointment_id._id}`}> {appointment_id.appointment_id} </Link> 
          : "";
      },
      sorter: (a, b) => {
        if (a.appointment_id && b.appointment_id) {
          return a.appointment_id.appointment_id.localeCompare(b.appointment_id.appointment_id);
        }
        return 0;
      },
    },       
    {
      title: "Order ID",
      key: "order_id",
      dataIndex: "order_id",
    },
    {
      title: "Order Date",
      key: "date_time_order",
      dataIndex: "date_time_order",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: "Booked By",
      key: "booked_by",
      dataIndex: "booked_by",
    },
    {
      title: "Booked For",
      key: "booked_for",
      dataIndex: "patient_id",
      render: (patient) => patient?.name || "N/A",
    },
    {
      title: "Doctor Name",
      key: "doctor_name",
      dataIndex: "doctor_name",
    },
    {
      title: "Order Type",
      key: "type",
      dataIndex: "type",
      render: (text, record) => {
        return record.type ? record.type : "";
      },
    },    
    {
      title: "Order Amount",
      key: "transaction_amount",
      dataIndex: "transaction_amount",
    },
    {
      title: "Payment Mode",
      key: "payment_mod",
      dataIndex: "payment_mod",
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
  }, [refresh, debouncedSearchText]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const filterUhid = filters? filters.uhid : null;
    const filterName = filters? filters.name: null;
    const filterEmail = filters? filters.email: null;
    const filterUserId = filters? filters.userId: null;

console.log(filterActive,"filterActive")
    request({
      url:
        api.patient +list,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data.data);
          // setTotalRecords(data.totalDocs)

          // setPagination((prev) => ({
          //   ...prev,
          //   // current: pagination.current,
          //   total: data.totalDocs,
          // }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  return (
    <>
      <SectionWrapper
        cardHeading={`Transaction Manager`}
        // cardSubheading={"(e.g New, Follow-Up)"}
        extra={
          <>
            {/* <div className="button_group justify-content-end w-100"> */}
            <div className="w-100 text-head_right_cont">
              {/* <Button
                disabled={!selectedIds.length}
                className="btnStyle btnOutlineDelete"
                onClick={() => {
                  if (!selectedIds.length) return;
                  showDeleteAllModal(true);
                }}
              >
                Delete All
              </Button> */}
              {/* <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                  setSearchText("");
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                Add New Type
              </Button> */}
            </div>
          </>
        }
      >
        {/* <h4 className="text-right mb-1cont-space cont-space">
                {pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}
              </h4> */}
        <div className="table-responsive customPagination checkBoxSrNo">
          <Table
            loading={loading}
            columns={columns}
            // rowSelection={rowSelection}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: false,
              showQuickJumper: false,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {deleteModal && (
        <DeleteModal
          title={"Delete Category"}
          subtitle={`Are you sure you want to Delete this appointment-price?`}
          show={deleteModal}
          hide={() => {
            showDeleteModal(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
        />
      )}

      {deleteAllModal && (
        <DeleteModal
          title={"Delete All Category"}
          subtitle={`Are you sure you want to Delete all appointment-price's?`}
          show={deleteAllModal}
          hide={() => {
            showDeleteAllModal(false);
            setSelectedIds([]);
          }}
          onOk={() => DeleteAll()}
        />
      )}
    </>
  );
}

export default Index;
