import {
  Button,
  Card,
  Select,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Upload,
  Switch,
  TimePicker,
  DatePicker,
} from "antd";
import * as _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";


import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import signinLogo from "../../assets/images/logo-black.png";

import signinbg from "../../assets/images/Content.png";
import UploadImage from "../User/User/_UploadImage";
import {
  PhoneNumberField,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import LocationMap from "../User/LocationMap";
import { Severty, ShowToast } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import useApi from "../../hooks/useApi";


const { Header, Content } = Layout;
const { Option } = Select;


const FileType = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  // "image/avif",
  // "image/webp",
  // "image/gif",
];


const Register = ({ data }) => {

  const { getState, getCity } = useApi();
  const [states, setStates] = useState([])

  const handleChange = (value, data) => {
    var country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value?.slice(data?.dialCode?.length),
    });
  };


  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const api = {
    signUp: apiPath.signUp,
    country: apiPath.common.countries,
    city: apiPath.common.city,
    fc: apiPath.common.foodCategories,
    rc: apiPath.common.restaurantCategories,
  };

  const [logo, setLogo] = useState();
  const [document, setDocument] = useState();

  const [image, setImage] = useState();

  const [loading, setLoading] = useState(false);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const [latLong, setlatLong] = useState({ lat: 30.5595, lng: 22.9375 });
  const [location, setLocation] = useState();

  const [mobileNumber, setMobileNumber] = useState(null);

  const [selectedTime, setSelectedTime] = useState({
    open: null,
    close: null,
  });

  const { request } = useRequest();

  const onFinish = (values) => {
    console.log(values, "hfjhdkghkhdgkd");
    const { location } = values
    let payload = {
      ...values,
      image: image,
      logo: logo,
      ...mobileNumber,
      ...location,
      document: document
    };
    console.log(payload, "payload");
    register(payload);
  };
  const onCreate = (values) => {
    const { firstName, lastName, email, state, country, city, salutation, gender, dob } = values;
    // Combine firstName and lastName into name
    const name = `${firstName} ${lastName}`;
    const payload = {};
    setLoading(true);
    payload.gender = gender;
    payload.salutation = salutation;
    payload.dob = dob;
    payload.name = name;
    payload.mobile_number = mobileNumber?.mobile_number;
    payload.country_code = mobileNumber?.country_code;
    payload.email = email;
    payload.image = image;
    payload.location = location?.location;
    payload.latitude = location?.latitude;
    payload.longitude = location?.longitude;
    payload.country = country;
    payload.state = state;
    payload.city = city;
    payload.postal_code = location?.postal_code;
    console.log(data, "data>>>>>>>>>.")
    request({
      url: apiPath.signUp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        navigate("/login")
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };
  useEffect(() => {
    console.log(selectedTime, "selectedTime");
  }, [selectedTime]);

  const register = (payload) => {
    request({
      url: api.signUp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        console.log(data, "fghdjh data");
        if (data.status) {
          setIsLoggedIn(true);
          // if (rememberMe) {
          //   // Store the login state in local storage
          //   var emailEncrypt = encryptDecrypt.encryptEmail(values.email);
          //   var passwordEncrypt = encryptDecrypt.encryptPassword(
          //     values.password,
          //   );
          //   localStorage.setItem("rememberMe", "true");
          //   localStorage.setItem("ykmCe2AYEFTHobn", emailEncrypt);
          //   localStorage.setItem("ouUsippetc8S4Ry", passwordEncrypt);
          // } else {
          localStorage.removeItem("ykmCe2AYEFTHobn");
          localStorage.removeItem("ouUsippetc8S4Ry");
          // }
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("userProfile", JSON.stringify(data.data.user));
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data.user);
          setTimeout(() => navigate("/dashboard"), 200);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onFinishFailed = () => { };

  const getCountry = () => {

    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, 'Country');
        if (data) {
          setCountries(data);
        }
      },
    });
  };



  const handleChangeLocation = (val) => {
    setLocation(val);
  };

  useEffect(() => {
    getCountry({
      countryData: (data) => setCountries(data)
    });
  }, [])


  const handleSelectChange = (value) => {

    getState({
      countryId: value,
      stateData: (data) => {
        setStates(data)
      }
    })
  };
  const handleStateChange = (value) => {

    getCity({
      stateId: value,
      cityData: (data) => setCities(data)
    })
  };


  return (
    <div className=" ">
      <Layout className="layout-default layout-signin">

        <Content className="register-main">
          <Row >
            <Col span={24} md={18} xl={14} className="mx-auto">

              <Form
                form={form}
                onFinish={onCreate}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                initialValues={{
                  dob: moment("1990-01-01", "YYYY-MM-DD"),
                }}
                className="row-col box-register"
              >

                <Row gutter={[24, 0]}>
                  <Col span={24}>

                    <div className="text-center">
                      <div className="sign-up-header ">
                        <h3 className="text-center">Create Your Account</h3>
                      </div>
                      <Form.Item
                        className="upload_wrap"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (image) {
                                return Promise.resolve();
                              }
                              // return Promise.reject(
                              //   new Error("Profile image is required")
                              // );
                            },
                          },
                        ]}
                        name="image"
                      >
                        <SingleImageUpload
              fileType={FileType}
              imageType={"image"}
              btnName={"Profile Picture"}
              onChange={(data) => setImage(data[0].url)}
            ></SingleImageUpload>
                        {/* <UploadImage value={image} setImage={setImage} /> */}
                      </Form.Item>
                    </div>
                  </Col>

                  <Col span={24} lg={12} sm={12}>
                    <Form.Item
                      label="Salutation"
                      name="salutation"
                      rules={[
                        { required: true, message: "Please select salutation!" },
                      ]}
                    >
                      <Select placeholder="Select Salutation">
                        <Option value="Mr">Mr</Option>
                        <Option value="Mrs">Mrs</Option>
                        <Option value="Miss">Miss</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={12} sm={12}>
                    <Form.Item
                      label="First Name"
                      name="firstName"
                      rules={[
                        { required: true, message: "Please enter the first name" },
                        {
                          max: 50,
                          message: "First Name should not contain more than 50 characters!",
                        },
                        {
                          min: 2,
                          message: "First Name should contain at least 2 characters!",
                        },
                      ]}
                      normalize={(value) => value.trimStart()}
                    >
                      <Input autoComplete="off" placeholder="Enter First Name" />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12} sm={12}>
                    <Form.Item
                      label="Last Name"
                      name="lastName"
                      rules={[
                        { required: true, message: "Please enter the last name" },
                        {
                          max: 50,
                          message: "Last Name should not contain more than 50 characters!",
                        },
                        {
                          min: 2,
                          message: "Last Name should contain at least 2 characters!",
                        },
                      ]}
                      normalize={(value) => value.trimStart()}
                    >
                      <Input autoComplete="off" placeholder="Enter Last Name" />
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={12} sm={12} className="flagMobileNumber">
                    <Form.Item
                      label="Mobile Number"
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          validator: (rule, value) => {
                            if (!value) {
                              return Promise.reject("Please enter phone number");
                            }
                            // if (!/^\d{8,15}$/.test(value)) {
                            //   return Promise.reject(
                            //     "Phone number must be between 8 and 18 digits"
                            //   );
                            // }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <PhoneInput
                        inputProps={{
                          name: "mobile",
                          required: true,
                          autoFocus: false,
                          autoFormat: false,
                          autoComplete: "off",
                        }}
                        isValid={(value, country) => {
                          if (value.match(/1234/)) {
                            return "Invalid value: " + value + ", " + country?.name;
                          } else if (value.match(/1234/)) {
                            return "Invalid value: " + value + ", " + country?.name;
                          } else {
                            return true;
                          }
                        }}
                        country={"ca"}
                        // preferredCountries={["ps", "il"]}
                        // value={
                        //   mobileNumber
                        //     ? (mobileNumber.country_code
                        //         ? mobileNumber.country_code
                        //         : "+27") +
                        //       (mobileNumber.mobile_number ? mobileNumber.mobile_number : null)
                        //     : "+27"
                        // }
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={8} sm={12}>
                    <Form.Item
                      label="Email ID"
                      name="email"
                      rules={[
                        { type: "email", message: "The email is not a valid email!" },
                        { required: true, message: "Please enter the email!" },
                        {
                          max: 50,
                          message: "Email should not contain more then 50 characters!",
                        },
                        {
                          min: 5,
                          message: "Email should contain at least 5 characters!",
                        },
                        {
                          pattern: new RegExp(
                            /^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
                          ),
                          message: "Enter valid email!",
                        },
                      ]}
                    >
                      <Input autoComplete="off" placeholder="Enter Email Address" />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={8} sm={12}>
                    <Form.Item
                      label="Gender"
                      name="gender"
                      rules={[
                        { required: true, message: "Please select gender!" },
                      ]}
                    >
                      <Select placeholder="Select Gender">
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                        <Option value="other">Other</Option>
                      </Select>
                    </Form.Item>
                  </Col>


                  <Col span={24} lg={8} sm={12}>
                    <Form.Item
                      label="Date of Birth"
                      name="dob"
                      rules={[
                        { required: true, message: "Please select Date of Birth!" },
                        {
                          validator: (_, value) => {
                            const isValidDate = moment(value, 'DD-MM-YYYY', true).isValid();
                            if (!isValidDate) {
                              return Promise.reject(new Error("Please enter a valid Date of Birth (DD-MM-YYYY)!"));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <DatePicker format="DD-MM-YYYY" placeholder="Select DOB" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={8} sm={12} className=" ">
                    <Form.Item
                      label="Country"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Please select the country!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        onChange={handleSelectChange}
                        placeholder="Select country"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {countries.length > 0 ? countries?.map((doc) => (
                          <Option key={doc._id} value={doc._id}>{doc.name}</Option>
                        )) : ""}
                      </Select>

                    </Form.Item>
                  </Col>

                  <Col span={24} lg={8} sm={12} className="">
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please select the state!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        onChange={handleStateChange}
                        placeholder="Select state"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {states.length > 0 ? states?.map((doc) => (
                          <Option key={doc._id} value={doc._id}>{doc.name}</Option>
                        )) : ""}
                      </Select>

                    </Form.Item>
                  </Col>


                  <Col span={24} lg={8} sm={12}>
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "Please select the city!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select City"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.length > 0 ? cities?.map((doc) => (
                          <Option key={doc._id} value={doc._id}>{doc.name}</Option>
                        )) : ""}
                      </Select>

                    </Form.Item>
                  </Col>

                  <Col span={24} lg={24} sm={24}  >
                    <Form.Item
                      label="Location (Drag Marker for Selecting Location)"
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: "Please select the location!",
                        },
                      ]}
                    >
                      <LocationMap
                        className="mt-3"
                        onChange={(val) => {
                          handleChangeLocation(val);
                        }}
                        userData={data}
                        editLocation={location}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <div className="reg-btn">
                      <Button
                        className=""
                        type="primary"
                        onClick={() => navigate("/login")}>
                        Back to Login
                      </Button>
                      <Button
                        className="btnStyle  primary_btn "
                        htmlType="submit"
                      >
                        submit
                      </Button>

                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};

export default Register;
