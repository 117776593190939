import React, { useState,useEffect } from "react";
import { Modal, Form, Input, Select, Collapse, Button , Card, Tag, Row, Col  } from "antd";
import useRequest from "../../hooks/useRequest";
import { Link, useParams,useNavigate } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import CasepaperImg from "../../assets/images/casepaperm-Img.png";
import {
  ArrowLeftOutlined
} from "@ant-design/icons";



const { TextArea } = Input;

const { Option } = Select;


const { Panel } = Collapse;

const tags = ['Diabetes', 'Diabetes', 'Diabetes', 'Diabetes', 'Diabetes', 'Diabetes'];

const Followup = ({ show, hide, refresh }) => {
  const [formKey, setFormKey] = useState(0);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { request } = useRequest();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [follow,setFollowUp] = useState()
  const [caseDetail,setCaseDetail] = useState({})
  const [isEditable, setIsEditable] = useState(true); // State to manage form editability

  const fetchFollowUp = (id) => {
    request({
      url:
       `${apiPath.PatientFollowUp}/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setFollowUp(data)
      },
      onError: (error) => {
       
      },
    });
  };

  console.log("follow:::::::::::::::::::",follow)
  const getcaseDetail = (id)=>{
    request({
      url:
       `${apiPath.CaseDetail}/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setCaseDetail(data)
      },
      onError: (error) => {

      },
    });
  }
  let id = params.id
const patiendId = caseDetail?.data?.patient_id
  console.log("caseDetail:::::::::::::",patiendId)
console.log("follow:::::::::::",follow)

const handleButtonClick = () => {
  navigate(`/appointment/createCase/${patiendId}`);
};
  useEffect(() => {
    setLoading(true);
    fetchFollowUp(params.id)
  }, []);

 
  // const onCreate = async (values) => {
  //   console.log(values, 'value>>>>>>>>')
  //   setLoading(true);
  //   try {
  //     const response = await request({
  //       url: apiPath.addFollowUp,
  //       method: "POST",
  //       data: values,
  //     });

  //     if (response.data) {
  //       ShowToast(response.data.message, Severty.SUCCESS);
  //       hide();
  //       refresh();
  //       window.history.back()
  //     } else {
  //       ShowToast(response.data.message, Severty.ERROR);
  //     }
  //   } catch (error) {
  //     ShowToast(error?.response?.data?.message, Severty.ERROR);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const onCreate = (values) => {
    setLoading(true);
    const payload = {
      ...values,
      appointment_id : params.id
    }
    request({
      url: apiPath.addFollowUp  ,
      method:  "POST"   ,
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        console.log(data,"data111111")
        if (data) {
          ShowToast(data.message, Severty.SUCCESS);
          navigate(-1)
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };


  return (
 <Card>
     <div className="main-follow-up-img">
       <div className="ant-card-head">
            <div className="ant-card-head-title">Follow-up form</div>
          </div>
      {/* <Collapse defaultActiveKey={['1']} expandIconPosition="right" > */}
      <Form key={formKey} id="create" form={form} layout="vertical" onFinish={onCreate}>
    <Row gutter={[48, 12]} className="pt-3 m-0">
      <Col span={24} md={12}>
        <Form.Item label="C/o" name="c_o">
          <Input placeholder="C/o" disabled={!isEditable} />
        </Form.Item>
      </Col>

      <Col span={24} md={12}>
        <Form.Item label="Nadi" name="nadi">
          <Input placeholder="Nadi" disabled={!isEditable} />
        </Form.Item>
      </Col>

      <Col span={24} md={12}>
        <Form.Item label="Mala" name="mala">
          <Input placeholder="Mala" disabled={!isEditable} />
        </Form.Item>
      </Col>

      <Col span={24} md={12}>
        <Form.Item label="Mutra" name="mutra">
          <Input placeholder="Mutra" disabled={!isEditable} />
        </Form.Item>
      </Col>

      <Col span={24} md={12}>
        <Form.Item label="Nidra" name="nidra">
          <Input placeholder="Nidra" disabled={!isEditable} />
        </Form.Item>
      </Col>

      <Col span={24} md={12}>
        <Form.Item label="Menstrual History" name="menstrual_history">
          <Input placeholder="Menstrual History" disabled={!isEditable} />
        </Form.Item>
      </Col>

      <Col span={24} md={12}>
        <Form.Item label="Other Findings" name="other_findings">
          <Input placeholder="Other Findings" disabled={!isEditable} />
        </Form.Item>
      </Col>

      <Col span={24} md={12}>
        <Form.Item label="Investigation" name="investigation">
          <Input placeholder="Investigation" disabled={!isEditable} />
        </Form.Item>
      </Col>

      <Col span={24} md={12}>
        <Form.Item label="Treatment" name="treatment">
          <Input placeholder="Treatment" disabled={!isEditable} />
        </Form.Item>
      </Col>
    </Row>
    <div className="save-button-main">
      <Button className="ant-btn ant-btn-default btnStyle  primary_btn"  onClick={() => navigate(-1)}><ArrowLeftOutlined />  Back</Button>
      <Button 
        className="btnStyle primary_btn carepaper-savebutton"
        type="button"
        htmlType="submit"
      >
        Save
      </Button>
      </div>
  </Form>
      {/* </Collapse> */}
      {/* <Collapse defaultActiveKey={['1']} expandIconPosition="right" className="mt-3" >
        <Panel header="Follow-up (Feb 28, 2024)" key="1"  className="main-courser-pointer-1">
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
              <label>C/o</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Nadi</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Mala</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Mutra</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Nidra</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Menstrual History</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Other Findings</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Collapse defaultActiveKey={['1']} expandIconPosition="right"  className="mt-3">
        <Panel header="Follow-up (Feb 20, 2024)" key="1" className="main-courser-pointer-1" >
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
              <label>C/o</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Nadi</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Mala</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Mutra</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Nidra</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Menstrual History</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
            <Col span={24} md={12}>
              <label>Other Findings</label>
              <Input placeholder="enter..." style={{ width: '100%' }} />
            </Col>
          </Row>
        </Panel>
      </Collapse> */}

   


     
    </div>
 </Card>

   
  );
};

export default Followup;
