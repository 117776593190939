import { UndoOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Select, Table, Tag, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CancelIcon from "../../assets/images/icon/CancelIcon.png";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Link } from "react-router-dom";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import EditForm from "./EditModal";
import EditIcon from "../../assets/images/edit.svg";
import ViewModal from "./ViewModal";
import Plus from "../../assets/images/plus.svg";
import moment from 'moment';
const {Option} = Select;
export const OrderStatus = {
  ACCEPT: "accepted",
  PENDING: "pending",
  PROCESSING: "processing",
  READY: "ready to pickup",
  PICKUP: "picked up",
  CANCEL: "cancelled",
  DELIVERED: "delivered",
};

const { RangePicker } = DatePicker;

function Index() {
  const { setPageHeading, country } = useContext(AppStateContext);
  const heading = lang("order") + " " + lang("management");

  const sectionName = "Category";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get('status');
  const params = useParams();

  const api = {
    status: apiPath.order,
    addEdit: apiPath.order,
    list: apiPath.order,
    importFile: apiPath.order + "/" + params.type,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [viewModal, showViewModal] = useState(false);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [cancelModal, showCancelModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  const handleChangeStatus = (id, status) => {
    request({
      url: api.status + "/" + id + "/" + status,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh(true);
        if (data.status) {

          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);

        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: "Order id",
      dataIndex: "order_id",
      key: "order_id",
      // render: (_, { uid }) => (uid ? <span className="cap">#{uid}</span> : "-"),
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) =>
        created_at ? <span className="cap">{moment(created_at).format("DD-MM-YYYY")}</span> : "-",
    },
    {
      title: "Order Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) =>
        created_at ? <span className="cap">{moment(created_at).format("HH:mm")}</span> : "-",
    },
    {
      title: "Appointment ID",
      dataIndex: "appointmentDetails",
      key: "appointment_id",
      filterMultiple: false,
      width: 200,
      render: (_, { appointmentDetails }) => {
        return appointmentDetails && appointmentDetails.appointment_id ? (
          <Link to={`/appointment/view/${appointmentDetails._id}`}>
            {appointmentDetails.appointment_id}
          </Link>
        ) : "-";
      },
      sorter: (a, b) => {
        if (a.appointmentDetails && b.appointmentDetails && a.appointmentDetails.appointment_id && b.appointmentDetails.appointment_id) {
          return a.appointmentDetails.appointment_id.localeCompare(b.appointmentDetails.appointment_id);
        }
        return 0;
      },
    },
    {
      title: "Appointment Date",
      dataIndex: "appointmentDetails",
      key: "appointment_at",
      render: (_, { appointmentDetails }) => {
        return appointmentDetails ? (
          <span className="cap">{moment(appointmentDetails.appointment_at).format("DD-MM-YYYY")}</span>
        ) : "-";
      },
    },    
    {
      title: "Appointment Type",
      dataIndex: "appointment_type",
      key: "appointment_type",
      render: (_, { appointmentDetails }) =>
        appointmentDetails ? <span className="cap">{appointmentDetails.appointment_type}</span> : "-",
    },
    {
      title: "Products",
      dataIndex: "productDetail",
      key: "products",
      render: (_, { productDetail }) =>
        productDetail.length > 0 ? (
          <ul>
            {productDetail.map((product) => (
              <li key={product._id}>
                {product.name} - {product.quantity} {product.unit}
              </li>
            ))}
          </ul>
        ) : (
          "-"
        ),
    },
    {
      title: "Booked By",
      dataIndex: "booked_by",
      key: "booked_by",
      render: (_, { userDetails }) =>
        userDetails ? <span className="cap">{userDetails.firstName + " "+ userDetails.lastName}</span> : "-",
    },
    {
      title: "Booked For",
      dataIndex: "booked_for",
      key: "booked_for",
      render: (_, { patientDetail }) =>
        patientDetail ? <span className="cap">{patientDetail.name}</span> : "-",
    },
    
    {
      title: "Doctor",
      dataIndex: "doctor_id",
      key: "doctor_id",
      render: (_, { doctorDetails }) =>
        doctorDetails ? <span className="cap">{doctorDetails.name}</span> : "-",
    },
    {
      title: "Agency",
      dataIndex: "agency",
      key: "agency",
      render: (_, { agency }) =>
        agency ? <span className="cap">{agency}</span> : "-",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, { userAddress }) =>
        userAddress ? <span className="cap">{userAddress.building_no+","+userAddress.city.name+ ","+ userAddress.country.name}</span> : "-",
    },
    {
      title: "Docket Number",
      dataIndex: "docketNumber",
      key: "docketNumber",
      render: (_, { docketNumber }) =>
        docketNumber ? <span className="cap">{docketNumber}</span> : "-",
    },
    {
      title: "Docket Date",
      dataIndex: "docketDate",
      key: "docketDate",
      render: (_, { docketDate }) =>
        docketDate ? <span className="cap">{moment(docketDate).format("DD-MM-YYYY")}</span> : "-",
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
      render: (_, { price }) =>
        price ? <span className="cap">${price}</span> : "-",
    },
    {
      title: "Discount Code",
      dataIndex: "discountCode",
      key: "discountCode",
      render: (_, { discountCode }) =>
        discountCode ? <span className="cap">{discountCode}</span> : "-",
    },
    {
      title: "Discounted Amount",
      dataIndex: "discountedAmount",
      key: "discountedAmount",
      render: (_, { discountedAmount }) =>
        discountedAmount ? <span className="cap">${discountedAmount}</span> : "-",
    },
    {
      title: "Discounted Price",
      dataIndex: "discountedPrice",
      key: "discountedPrice",
      render: (_, { discountedPrice }) =>
        discountedPrice ? <span className="cap">${discountedPrice}</span> : "-",
    },
    {
      title: "Status",
      key: "orderStatus",
      dataIndex: "orderStatus",
      filters: [
        {
          text: "Received",
          value: "Received",
        },
        {
          text: "Shipped",
          value: "Shipped",
        },
        {
          text: "Delivered",
          value: "Delivered",
        },
        {
          text: "Refunded",
          value: "Refunded",
        },
        {
          text: "Cancelled",
          value: "Cancelled",
        },
      ],
      render: (_, { orderStatus, _id }) => {
        return (
          <a>
              <Select
      value={orderStatus}
      style={{ width: 120 }}
      onChange={(value) => handleChangeStatus(_id, value, "type")}
    >
      <Option value="Received">Received</Option>
      <Option value="Shipped">Shipped</Option>
      <Option value="Delivered">Delivered</Option>
      <Option value="Cancelled">Cancelled</Option>
      <Option value="Refunded">Refunded</Option>

    </Select>
          </a>
        );
      },
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (_, { deliveryDate }) =>
        deliveryDate ? <span className="cap">{moment(deliveryDate).format("DD-MM-YYYY")}</span> : "-",
    },
  
  ];

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
  }, [refresh, debouncedSearchText, startDate, endDate]);

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.orderStatus : null;
    const filterStatus = filters ? filters.status : null; // Add status filter
    

    request({
      url:
        api.list +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${pagination ? pagination.current : 1
        }&pageSize=${pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${path ? `&status=${path}` : ''}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data.docs);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: data.totalDocs,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    } else {
      setStartDate()
      setEndDate()
    }
  };


  return (
    <>
      <SectionWrapper
        cardHeading={"Ongoing Orders List"}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              {/* <Button
                onClick={() => setFilter({
                  country_id: undefined,
                  city_id: undefined,
                  year: undefined,
                  month: undefined,
                })}
                type="primary" icon={<UndoOutlined />}>
                Reset
              </Button> */}
              <div className="pageHeadingSearch d-flex gap-2">
                <RangePicker style={{ height: 42 }} disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} />
                <Input.Search
                  className="searchInput"
                  placeholder="Search by customer name, Phone number, email"
                  onChange={onSearch}
                  allowClear
                />
              </div>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: false,
              showQuickJumper: false,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <EditForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {viewModal && (
        <ViewModal
          api={api}
          show={viewModal}
          hide={() => {
            setSelected();
            showViewModal(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {cancelModal && (
        <DeleteModal
          title={"Cancel Order"}
          subtitle={`Are you sure you want to cancel this order?`}
          show={cancelModal}
          hide={() => {
            showCancelModal(false);
            setSelected();
          }}
          onOk={() => handleChangeStatus(selected?._id, OrderStatus.CANCEL)}
        />
      )}
    </>
  );
}

export default Index;
