import {
  Button,
  DatePicker,
  Input,
  Table,
  Tag,
  Row,
  Col,
  Select,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import useApi from "../../../hooks/useApi";
import { calculateAge } from "../../../helper/functions";

import Plus from "../../../assets/images/plus.svg";
import ConfirmationBox from "../../../components/ConfirmationBox";
import SectionWrapper from "../../../components/SectionWrapper";
import apiPath from "../../../constants/apiPath";
import { AppStateContext, useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { Severty, ShowToast } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import { useNavigate } from "react-router";

const { RangePicker } = DatePicker;
const { Option } = Select;

function PatientHistory() {
  const heading = lang("Patient History");
  const { setPageHeading } = useContext(AppStateContext);
  const { country } = useAppContext();
  const { getState, getCity, getCountry } = useApi();

  const sectionName = "Patient";
  const routeName = "patient";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");

  const api = {
    patient: apiPath.listPatientHistory,
    addEdit: apiPath.listPatientHistory,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const navigate = useNavigate();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();

  const [cities, setCities] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [selectedOptionsCountries, setSelectedOptionsCountries] = useState();
  const [selectedState, setselectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const params = useParams()
  //For Filters
  const [filter, setFilter] = useState();

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);


 

  const columns = [
    {
      title: "Appointment id",
      dataIndex: "appointment_id",
      key: "appointment_id",
      filters: [
        {
          text: "A-Z",
          value: 1,
        },
        {
          text: "Z-A",
          value: -1,
        },
      ],
      filterMultiple: false,
      width: 200,
      render: (_, { appointment_id, _id }) => {
        return appointment_id ? (
          appointment_id
        ) : (
          _id
        );
      },
    },
    
   

    {
      title: "Dr. Name",
      dataIndex: "name",
      key: "firstName",
      filterMultiple: false,
      width: 150,
      onFilter: (value, record) => {
        const firstName = record?.name?.split(" ")[0] || "";
        return firstName.toLowerCase().startsWith(value.toLowerCase());
      },
      sorter: (a, b) => {
        const firstNameA = (a?.name?.split(" ")[0] || "").toLowerCase();
        const firstNameB = (b?.name?.split(" ")[0] || "").toLowerCase();
        return firstNameA.localeCompare(firstNameB);
      },
      render: (text, {doctor_id}) => {
        const firstName = doctor_id?.name || "-";
        return firstName;
      },
    },

    {
      title: "STATUS REASON",
      key: "status",   
      render: (_, { status, _id }) => {
        const color =  status === "New" ? "blue" : "green"
        return (
          <a>
            <Tag 
                    
              color={color}
              key={status}
            >
              {status}
            </Tag>
          </a>
        );
      },
    },
    {
      title: "Appointment Type",
      key: "appointment_type",   
      render: (_, { appointment_type, _id }) => {
        const color =  appointment_type === "New" ? "green" : "blue"
        return (
          <a>
            <Tag 
                    
              color={color}
              key={appointment_type}
            >
              {appointment_type}
            </Tag>
          </a>
        );
      },
    },
    {
      title: "Register Date",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("lll");
      },
    },
    {
      title: "Action",
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <>
              <Tooltip
                title={"View Details"}
                color={"purple"}
                key={"activity user"}
              >
                <Button
                  className="btnStyle primary_btn"
                  onClick={(e) =>  navigate(`/patient/details/${record?._id}`)}
                >
                 <i className="fa fa-light fa-eye"></i>

                </Button>
              </Tooltip>
            
            </>
          </div>
        );
      },
    },
  ];


 

  
  useEffect(() => {
    getCountry({
      countryData: (data) => setCountries(data),
    });
  }, []);
  useEffect(() => {
    if(!params.id) return;
    setLoading(true);
    fetchData({ ...pagination, current: 1 }, filter);
  }, [
    refresh,
    debouncedSearchText,
    startDate,
    endDate,
    selectedCity,
    selectedState,
    selectedOptionsCountries,
    params.id
  ]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {

    const patientId = params?.id ? params?.id : "" 


    const filterActive = filters ? filters.is_active : null;
    const filterUhid = filters ? filters.uhid : null;
    const filterName = filters ? filters.name : null;
    const filterEmail = filters ? filters.email : null;
    const filterUserId = filters ? filters.userId : null;

    console.log(filterActive, "filterActive");
    request({
      url:
        api.patient +
        `?patientId=${patientId}&status=${filterActive ? filterActive.join(",") : ""}&uhid=${
          filterUhid ? filterUhid.join(",") : ""
        }&userId=${filterUserId ? filterUserId.join(",") : ""}&name=${
          filterName ? filterName.join(",") : ""
        }&email=${filterEmail ? filterEmail.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${path ? `&status=1` : ""}&start_date=${
          startDate ? startDate : ""
        }&end_date=${endDate ? endDate : ""}&country=${
          selectedOptionsCountries ? selectedOptionsCountries : ""
        }&state=${selectedState ? selectedState : ""}&city=${
          selectedCity ? selectedCity : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data.data);
          setTotalRecords(data?.data?.length);
          setPagination((prev) => ({
            current: pagination?.current,
            total: data?.data?.length,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    setFilter(filters);
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStartDate();
      setEndDate();
    }
  };

  const handleReset = () => {
    setSelectedCity(null);
    setselectedState(null);
    setSelectedOptionsCountries(null);
    setRefresh((prev) => !prev);
    setPagination({ current: 1, pageSize: 10 });
    setStartDate();
    setEndDate();
    setSearchText("");
    setStates([])
    setCities([])
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Patients") + " " + lang("history list")}
        extra={
          <>
            <div className="w-100 d-grid align-items-baseline text-head_right_cont">
              <div className="pageHeadingSearch pageHeadingbig d-flex gap-2">
                <RangePicker
                  style={{ height: 44 }}
                  disabledDate={(current) => current.isAfter(Date.now())}
                  value={[
                    startDate ? moment(startDate) : null,
                    endDate ? moment(endDate) : null,
                  ]}
                  onChange={handleChangeDate}
                />
                <Input.Search
                  className="searchInput"
                  placeholder="Search by Appointment ID"
                  onChange={onSearch}
                  value={searchText}
                  allowClear
                />
              
              <Button
                  className="btnStyle  primary_btn"
                  onClick={() => handleReset()}
                >
                  Reset
                </Button>
              </div>
              
            </div>
          </>
        }
      >
        <h4 className="text-right">TotalRecords: {totalRecords}</h4>
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={pagination}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

    

    
    </>
  );
}

export default PatientHistory;
