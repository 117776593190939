import { Row, Col, Card, Button, Skeleton, Image, Divider, Tag } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import apiPath from "../../../constants/apiPath";
import { Badge } from 'antd';
import moment from "moment";
import Plus from "../../../assets/images/plus.svg";


import notfound from "../../../assets/images/not_found.png";
import { AppStateContext } from "../../../context/AppContext";
import AddFeedback from "./_AddFeedback";
import AddDocAdvice from "./_AddDocAdvice";
// import pdffile from "../../assets/images/pdf-file.png";
function AppointmentDetails() {

  const { setPageHeading } = useContext(AppStateContext);
  const heading = ("Appointments");
  const sectionName = "Appointments ";
  const routeName = "appointments";
const api = {
  addFeedback : apiPath.addDrFeedback,
  addDocAdvice : apiPath.addDocAdvice,
  approveDocument : apiPath.approveDocument
}
  const params = useParams();
  const { request } = useRequest();
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);
  const [feedback,setFeedback] = useState()
  const [visibleFeedback,setVisibleFeedback] = useState(false)
  const [visibleAdvice,setVisibleAdvice] = useState(false)
  const navigate = useNavigate()
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);
const imageUrl =  `https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/invent-colab-obj-bucket`

  const fetchData = (id) => {
    request({
      url: apiPath.patientDetails + "/" + id,
      method: 'GET',
      onSuccess: ({data}) => {
        setLoading(false);
        setList(data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const fetchFeedback = (id) => {
    request({
      url: apiPath.drFeedback + "/" + id,
      method: 'GET',
      onSuccess: ({data}) => {
        setLoading(false);
        setFeedback(data?.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const approveDocs = ({appointment_id , approvePrecriptionPdf ,approveAdvisoryNotes,approveLabReports}) => {
    const payload = {
      appointment_id :  appointment_id,
     };

     if(approvePrecriptionPdf) payload.approvePrecriptionPdf = approvePrecriptionPdf ? approvePrecriptionPdf : ""
     if(approveAdvisoryNotes)  payload.approveAdvisoryNotes = approveAdvisoryNotes ? approveAdvisoryNotes : ""
     if(approveLabReports)     payload.approveLabReports = approveLabReports ? approveLabReports : ""

    request({
      url: `${api.approveDocument }`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setRefresh((prev)=> !prev);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };




console.log("list::::::::::::::::::::::",list)
console.log("check console",list)
  useEffect(() => {
    setLoading(true)
    fetchData(params.id)
    fetchFeedback(params.id)
  }, [refresh])

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  return (
    <>
     <Card className="appointmrnt-h" title={"Appointment" + " Details"}>
  <Row gutter={16}>
    <Col span={12} xs={24} md={24}>
      {loading? <Skeleton active /> :
        <div className="Case-main-list">
           <div className="view-inner-cls">
            <h6 className="cap"> 
              <Button
                  className="primary_btn btnStyle"
                  onClick={(e) => {
                    setVisibleAdvice(true);
                    setSelected(list)
                  }}
                >
                 
                 {list?.documentation ? "Edit" : "Add"} Documentation Advice
                </Button></h6>
          </div>
          <div className="view-inner-cls">
            <h5>Appointment ID:</h5>
            <h6 className="cap">{list?.appointment_id? list?.appointment_id : '-'}</h6>
          </div>
          <div className="view-inner-cls">
            <h5>Last Status:</h5>
            <h6 className="cap">{list?.status ? list?.status : '-'}</h6>
          </div>
          <div className="view-inner-cls">
            <h5>Advisory Notes:</h5>
            <h6 className="cap">{list?.advisoryNotes ?  
            <><a href={`${imageUrl}/${list.advisoryNotes}` } download target="_blank" rel="noopener noreferrer"> Download PDF </a>
              {list?.approveAdvisoryNotes ?
               <Tag className=" " color={"green" } >Approved</Tag>
            :  <Tag className=" " color={"yellow"} onClick={(e) => {   approveDocs({appointment_id : list._id , approveAdvisoryNotes : true , approveLabReports : true ,approvePrecriptionPdf : list?.approvePrecriptionPdf }) }}>Approve</Tag> }</>  : '-'}</h6>
          </div>
          <div className="view-inner-cls">
            <h5>Lab Report:</h5>
            <h6 className="cap">{list?.labReports ?   
            <><a href={`${imageUrl}/${list.labReports}`} download target="_blank" rel="noopener noreferrer"> Download PDF </a> 
            {list?.approveLabReports ? 
            <Tag color={"green"} className="">Approved</Tag>
          : <Tag color={"yellow"} className=" " onClick={(e) => {   approveDocs({appointment_id: list?._id , approveLabReports : true ,approvePrecriptionPdf : list?.approvePrecriptionPdf ,approveAdvisoryNotes : list?.approveAdvisoryNotes}) }}>Approve</Tag>}</>  : '-'}</h6>
          </div>
          <div className="view-inner-cls">
            <h5>Prescription:</h5>
            <h6 className="cap">{list?.precriptionPdf ? 
             <><a href={`${imageUrl}/${list.precriptionPdf}`} download target="_blank" rel="noopener noreferrer"> Download PDF </a>
             { list?.approvePrecriptionPdf ?
              <Tag className=" "  color={"green"} >Approved</Tag> 
            : <Tag className=" "  color={"yellow"} onClick={(e) => {  approveDocs({appointment_id: list._id , approvePrecriptionPdf : true , approveLabReports : list?.approveLabReports ,approveAdvisoryNotes : list?.approveAdvisoryNotes })}}>Approve</Tag>}</>   : '-'}</h6>
           </div>
         
          <div className="view-inner-cls">
            <h5>Products Recommended:</h5>
            
            <div className="view-inner-clsiju988">
              {list?.cartId?.products?.length && list?.cartId?.products?.map((item)=>(
               <>
             <div className="main-product-1"> 
             <div className="extra-price-9ik">
             <div className="main-product-1-image">  {item?.image? <Image src={item?.image}/>  : '-'}</div>
              <div className="main-product-1-text">
              <h3>{item?.name}</h3>
              <p>${item?.price ?? 0}</p>
               
              </div>
              
             </div>
             <div className="extra-price-9i"><Tag>Qty {item?.quantity ?? 0}</Tag></div>
             </div>
               </>
              ))

              }
            </div>
          </div>


          
          <div className="view-inner-cls">
            <h5>Doctor's Feedback:</h5>
            {/* <h6 className="cap">{list?.treatment? list?.treatment?.join(', ') : '-'}</h6> */}
            <div>
              {feedback?.length ? feedback?.map((item)=>(
                <>
               <p>{item?.review}</p>
               <p>{item?.created_at ? moment(item?.created_at).format("lll") : "-"}</p>
               </>
              ))
             : ""
              }
            </div>
          </div>
          <div className="view-inner-cls">
            <h6 className="cap"> 
              <Button
                  className="primary_btn btnStyle"
                  onClick={(e) => {
                    setVisibleFeedback(true);
                    setSelected(list)
                    console.log(list,"listlistlistlist")
                  }}
                >
                  <span className="add-Ic">
                    <img src={Plus} />
                  </span>
                  Add Your Feedback 
                </Button></h6>
          </div>

          <div className="view-inner-cls float-right">
            <Link className="ant-btn ant-btn-primary" onClick={()=> navigate(-1)}>Back</Link>
          </div>

        </div>
      }
    </Col>
  </Row>
</Card>
   {visibleFeedback && (
        <AddFeedback
          section={sectionName}
          api={api}
          show={visibleFeedback}
          hide={() => {
            setSelected();
            setVisibleFeedback(false);
          }}
          data={list}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
       {visibleAdvice && (
        <AddDocAdvice
          section={sectionName}
          api={api}
          show={visibleAdvice}
          hide={() => {
            setSelected();
            setVisibleAdvice(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );

}

export default AppointmentDetails;



