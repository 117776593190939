import {
    Button,
    DatePicker,
    Table,
    Tag,
    Select,
  } from "antd";
  import moment from "moment";
  import React, { useContext, useEffect, useState } from "react";
  import { Link, useParams } from "react-router-dom";
  import * as XLSX from "xlsx";
  import useApi from "../../hooks/useApi";
  
  import Plus from "../../assets/images/plus.svg";
  import ConfirmationBox from "../../components/ConfirmationBox";
  import DeleteModal from "../../components/DeleteModal";
  import SectionWrapper from "../../components/SectionWrapper";
  import apiPath from "../../constants/apiPath";
  import { AppStateContext, useAppContext } from "../../context/AppContext";
  import lang from "../../helper/langHelper";
  import { Severty, ShowToast } from "../../helper/toast";
  import useDebounce from "../../hooks/useDebounce";
  import useRequest from "../../hooks/useRequest";
  import AddFrom from "./AddFrom";
  import { useNavigate } from "react-router";
  
  const { RangePicker } = DatePicker;
  
  function Index() {
    const heading = lang("Leave") + " " + lang("management");
    const { setPageHeading } = useContext(AppStateContext);
    const { getState, getCity, getCountry } = useApi();
  
    const sectionName = "Leave";
    const routeName = "patient";
    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get("status");
  
    const api = {
      patient: apiPath.listLeave,
      addEdit: apiPath.listPatient,
    };
  
    const [searchText, setSearchText] = useState("");
    const { request } = useRequest();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selected, setSelected] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [totalRecords, setTotalRecords] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const [selectedOptionsCountries, setSelectedOptionsCountries] = useState();
    const [selectedState, setselectedState] = useState();
    const [selectedCity, setSelectedCity] = useState();
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const debouncedSearchText = useDebounce(searchText, 300);
  
    const onDelete = (id) => {
      request({
        url: api.patient + "/" + id,
        method: "DELETE",
        onSuccess: (data) => {
          setLoading(false);
          setRefresh((prev) => !prev);
        },
        onError: (error) => {
          setLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    };
  

  console.log("list::::::::::::::::::::::::",list)
    const columns = [
      {
        title: "Doctor uhid",
        dataIndex: "doctorId",
        key: "doctorId",
        filterMultiple: false,
        width: 200,
        render: (_, { doctorId, _id }) => {
          return doctorId ? (
            <Link to={`/doctor/view/${doctorId._id}`}> {doctorId.uhid} </Link>
          ) : (
            _id
          );
        },
        sorter: (a, b) => {
          let nameA = a.doctorId.uhid?.toLowerCase();
          let nameB = b.doctorId.uhid?.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        },
      },
      {
        title: "Start Date",
        dataIndex: "startDate",
        key: "startDate",
        width: 200,
        render: (_, { startDate }) => {
          return startDate ? moment(startDate).format("ll") : "-";
        },
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        width: 200,
        render: (_, { endDate }) => {
          return endDate ? moment(endDate).format("ll") : "-";
        },
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
        width: 200,
        render: (_, { reason }) => {
          return reason ? reason.trim() : "-"; // If reason exists, return it, otherwise return a placeholder
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        filters: [
          {
            text: "Vacation",
            value: "Vacation",
          },
          {
            text: "Medical",
            value: "Medical",
          },
          {
            text: "Other",
            value: "Other",
          },
        ],
        filterMultiple: false,
        width: 200,
        render: (_, { type, otherTypeDescription }) => {
          return type === "Other" && otherTypeDescription
            ? `Other - ${otherTypeDescription}`
            : type;
        },
      },
      {
        title: "Status",
        dataIndex: "leaveStatus",
        key: "leaveStatus",
        filters: [
          {
            text: "Pending",
            value: "Pending",
          },
          {
            text: "Approved",
            value: "Approved",
          },
          {
            text: "Rejected",
            value: "Rejected",
          },
        ],
        filterMultiple: false,
        width: 200,
        render: (_, { leaveStatus }) => {
          let color = leaveStatus === "Approved" ? "green" : leaveStatus === "Rejected" ? "red" : "orange";
          return (
            <Tag color={color} key={leaveStatus}>
              {leaveStatus}
            </Tag>
          );
        },
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        render: (_, { created_at }) => {
          return moment(created_at).format("ll");
        },
      },
    ];
    
   
    
    useEffect(() => {
      setLoading(true);
      fetchData({ ...pagination, current: 1 });
    }, [
      refresh,
      debouncedSearchText,
      startDate,
      endDate,
      selectedCity,
      selectedState,
      selectedOptionsCountries,
    ]);
  
    useEffect(() => {
      setPageHeading(heading);
    }, [setPageHeading]);
  
    const fetchData = (pagination, filters) => {
      const filterActive = filters ? filters.leaveStatus : '';
      const filterType = filters ? filters.type : '';
     
      console.log(filterActive, "filterActive");
      request({
        url:
          `${api.patient}?type=${filterType? filterType: ""}&status=${filterActive? filterActive: ""}`,
        method: "GET",
        onSuccess: (data) => {
          setLoading(false);
            setList(data.getLeave);
            setTotalRecords(data.totalDocs);
        },
        onError: (error) => {
          setLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    };
  
    const handleChange = (pagination, filters) => {
      fetchData(pagination, filters);
    };
  
    const onSearch = (e) => {
      setSearchText(e.target.value);
    };
  
    const handleChangeDate = (e) => {
      if (e != null) {
        setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
        setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
      } else {
        setStartDate();
        setEndDate();
      }
    };
  
    const handleExport = () => {
      const data =
        list &&
        list.length > 0 &&
        list.map((row, index) => ({
          "S.No.": index,
          "User Id": row.uhid,
          "User Name": row.name,
          "User Email": row.email,
          "User MobileNo.": `${row.country_code}${row.mobile_number}`,
          Gender: row.gender,
          DOB: row.dob,
          Location: row.location,
  
          Status: row.is_active,
          "Registered On": moment(row.created_at).format("DD_MM_YYYY"),
        }));
      // alert(row.languageId.name)
  
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Patient Data");
      XLSX.writeFile(
        workbook,
        `${
          moment().milliseconds() +
          1000 * (moment().seconds() + 60 * 60) +
          "-access"
        }.xlsx`
      );
    };
    const handleReset = () => {
      setSelectedCity(null);
      setselectedState(null);
      setSelectedOptionsCountries(null);
      setRefresh((prev) => !prev);
      setPagination({ current: 1, pageSize: 10 });
      setStartDate();
      setEndDate();
      setSearchText("");
    };
  
    return (
      <>
        <SectionWrapper
          cardHeading={lang("Leave") + " " + lang("list")}
          extra={
            <>
              <div className="w-100 d-grid align-items-baseline text-head_right_cont">
                <div className="pageHeadingSearch pageHeadingbig d-flex gap-2">
                  <RangePicker
                    style={{ height: 44 }}
                    disabledDate={(current) => current.isAfter(Date.now())}
                    value={[
                      startDate ? moment(startDate) : null,
                      endDate ? moment(endDate) : null,
                    ]}
                    onChange={handleChangeDate}
                  />
                  {/* <Input.Search
                    className="searchInput"
                    placeholder="Name, Number, Email, UHID, UserId"
                    onChange={onSearch}
                    value={searchText}
                    allowClear
                  /> */}
                 <Button
                    className="primary_btn btnStyle"
                    onClick={(e) => {
                      setVisible(true);
                      setSearchText("");
                    }}
                  >
                    <span className="add-Ic">
                      <img src={Plus} />
                    </span>
                    Add {sectionName}
                  </Button>
                  {/* <Button
                    className="btnStyle  primary_btn"
                    onClick={() => handleExport()}
                  >
                    Export
                  </Button> */}
                </div>
                {/* <Row className="justify-content-end gap-2">
                  <Select
                    showSearch
                    className="multiselect"
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    placeholder="Select Country"
                    // mode="multiple"
                    value={selectedOptionsCountries}
                    onChange={handleChangeCountries}
                  >
                    {countries && countries.length > 0
                      ? countries.map((item, index) => (
                          <Option value={item._id}>{item.name}</Option>
                        ))
                      : null}
                  </Select>
  
                  <Select
                    showSearch
                    className="multiselect"
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    placeholder="Select State"
                    // mode="multiple"
                    value={selectedState}
                    onChange={handleChangeState}
                  >
                    {states && states.length > 0
                      ? states.map((item, index) => (
                          <Option value={item._id}>{item.name}</Option>
                        ))
                      : null}
                  </Select>
  
                  <Select
                    showSearch
                    className="multiselect"
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    placeholder="Select City"
                    // mode="multiple"
                    value={selectedCity}
                    onChange={handleChangeCity}
                  >
                    {cities && cities.length > 0
                      ? cities.map((item, index) => (
                          <Option value={item._id}>{item.name}</Option>
                        ))
                      : null}
                  </Select>
                  <Button
                    className="btnStyle  primary_btn"
                    onClick={() => handleReset()}
                  >
                    Reset
                  </Button>
                </Row> */}
              </div>
            </>
          }
        >
          {/* <h4 className="text-right">TotalRecords: {totalRecords}</h4> */}
          <div className="table-responsive customPagination">
            <Table
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={pagination}
              onChange={handleChange}
              className="ant-border-space"
            />
          </div>
        </SectionWrapper>
  
        {visible && (
          <AddFrom
            section={sectionName}
            api={api}
            show={visible}
            hide={() => {
              setSelected();
              setVisible(false);
            }}
            data={selected}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}
  
        {showDelete && (
          <DeleteModal
            title={"Delete Patient"}
            subtitle={`Are you sure you want to Delete this patient?`}
            show={showDelete}
            hide={() => {
              setShowDelete(false);
              setSelected();
            }}
            onOk={() => onDelete(selected?._id)}
          />
        )}
      </>
    );
  }
  
  export default Index;
  