import {
  BarsOutlined,
  DownOutlined,
  KeyOutlined,
  LogoutOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Upload,
  message,
  DatePicker,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { uploadFile } from "react-s3";
import notfound from "../../assets/images/not_found.png";
import Notification from "../../assets/images/notification.svg";
import DeleteModal from "../../components/DeleteModal";
import { s3Config } from "../../config/s3Config";
import LogoBlack from "../../assets/images/logo-black.png";
import apiPath from "../../constants/apiPath";
import { AuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import { useAppContext } from "../../context/AppContext";
import moment from "moment";
import SingleImageUpload from "../SingleImageUpload";
import PhoneInput from "react-phone-input-2";
const { Option } = Select;
const { confirm } = Modal;
const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

const currency = ["AED", "INR"];

function Header({ name: sectionHeading, onPress, setToggle }) {
  useEffect(() => window.scrollTo(0, 0));
  const [visible, setVisible] = useState(false);

  const [profile, setProfile] = useState({});
  const [selected, setSelected] = useState();
  const [profileVisible, setProfileVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [appSetting, setAppSetting] = useState({});
  // const [breadcrumb, setBreadcrumb] = useState(name?.split('/') ?? []);
  const [appSettingSelected, setAppSettingSelected] = useState();
  const [appSettingVisible, setAppSettingVisible] = useState(false);
  const [statusVisible, setStatusVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const { request } = useRequest();
  const [refresh, setRefresh] = useState(false);
  const { logout } = useContext(AuthContext);
  const { setCountry, country } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const [unread, setUnread] = useState([]);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  useEffect(() => {
    getCountry();
    getNotification();
  }, []);

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
          data.length &&
            setCountry((prev) => ({ ...prev, country_id: data[0]._id }));
        }
      },
    });
  };

  const getNotification = () => {
    request({
      url: "/app/notification/list",
      method: "GET",
      onSuccess: (data) => {
        const unreadNotifications = data.data.filter(
          (notification) => !notification.is_read
        );
        // Store the length of unread notifications in a variable
        const unreadNotificationsLength = unreadNotifications.length;
        setUnread(unreadNotificationsLength);
        const firstFiveNotifications = data.data.slice(0, 5); // Get the first five items
        setNotification(firstFiveNotifications);
        console.log("check data", firstFiveNotifications);
      },
    });
  };

  const readNotification = (Id) => {
    request({
      url: `/app/notification/read?id=${Id ? Id : ""}`,
      method: "post",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast(data?.message, Severty.SUCCESS);
      },
      onError: (error) => {
        setLoading(false);
      },
    });
    setLoading(false);
  };

  const deleteNotification = (Id) => {
    request({
      url: `/app/notification/delete?id=${Id ? Id : ""}`,
      method: "post",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast(data?.message, Severty.SUCCESS);
      },
      onError: (error) => {
        setLoading(false);
      },
    });
    setLoading(false);
  };

  const onChange = (key, value) => {
    setCountry((prev) => ({ ...prev, [key]: value }));
  };

  const notificationitems = [
    {
      label: (
        <div className="notification_top">
          <div className="notification-head">
            <h5>Notifications</h5>
          </div>
          <div className="notification-inner">
            {notification.map((data, index) => (
              <div className="single-notification" key={index}>
                <div className="notification-img">
                  <img src={LogoBlack} alt="Notification" />
                </div>

                <div className="notification-cont">
                  <p>{data.description}</p>
                  <p>
                    {moment(data.created_at).format("LLL")}{" "}
                    {/* Formats the date as "Month Day, Year, Time" */}
                  </p>
                  <Button
                    className="btnStyle btn_primary"
                    onClick={() => readNotification(data._id)}
                  >
                    {data.is_read ? (
                      <i className="fa fa-light fa-eye"></i>
                    ) : (
                      <i className="fa fa-light fa-eye-slash"></i>
                    )}
                  </Button>
                  {/* <Button  className="btnStyle btn_primary" onClick={() => deleteNotification(data._id)}>
                    <i className="fa fa-light fa-trash"></i>
                   
                  </Button> */}
                </div>
              </div>
            ))}
          </div>

          <div className="viewAll_notification">
            <Button
              onClick={() => {
                readNotification();
                navigate("/notification");
              }}
              className="btnStyle btn_primary"
            >
              View All
            </Button>
            {/* <Button
              onClick={() => {deleteNotification(); }}
              className="btnStyle btn_primary"
            >
              Delete All
            </Button> */}
          </div>
        </div>
      ),
    },
  ];

  const items = [
    {
      label: "Account Information",
      key: "1",
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: "Status",
      key: "4",
      icon: <BarsOutlined />,
      danger: true,
    },
    // {
    //   label: "Change Password",
    //   key: "2",
    //   icon: <KeyOutlined />,
    //   danger: true,
    // },
    {
      label: "Logout",
      key: "3",
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];

  useEffect(() => {
    if (!isOpen) return document.body.classList.remove("edit-dropdown");
    document.body.classList.add("edit-dropdown");

    return () => {
      document.body.classList.remove("edit-dropdown");
    };
  }, [isOpen]);

  const showDeleteConfirm = (record) => {
    setIsLogoutModalVisible(true);
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    // setTimeout(() => {
    //   confirm({
    //     okText: "Logout",
    //     okType: "danger",
    //     icon: <QuestionCircleFilled />,
    //     cancelText: "Cancel",
    //     content: <Button>Are you sure you want to logout ?</Button>,
    //     onOk() {
    //       logout();
    //     },
    //     onCancel() {
    //       console.log("Cancel");
    //     },
    //   });
    // }, 5);
  };

  const handleMenuClick = (e) => {
    setIsOpen(false);
    if (e.key == 2) {
      setVisible(true);
    }
    if (e.key == 1) {
      setProfileVisible(true);
    }
    if (e.key == 4) {
      setStatusVisible(true);
    }
    if (e.key == 3) {
      showDeleteConfirm();
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleCreate = (values) => {
    onCreate(values);
  };

  const onCreate = (values) => {
    const { old_password, new_password } = values;
    const payload = {};
    if (!old_password || !new_password)
      return ShowToast("Please enter password ", Severty.ERROR);
    setLoading(true);
    payload.new_password = new_password;
    payload.old_password = old_password;
    request({
      url: apiPath.changePassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(`Logged Out! ${data.message}`, Severty.SUCCESS);
          setVisible(false);
          logout();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onAvailableStatusChange = (values) => {
    const payload = { ...values };
    setLoading(true);
    request({
      url: apiPath.availablityStatusChange,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setRefresh((prev) => !prev);
          setStatusVisible(false);
        } else {
          ShowToast(data?.message, Severty.ERROR);
          setStatusVisible(false);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    // setBreadcrumb(sectionHeading?.split("/") ?? []);
  }, [sectionHeading]);

  useEffect(() => {
    request({
      url: apiPath.profile,
      method: "GET",
      onSuccess: (data) => {
        setProfile(data.data);
        setSelected(data.data);
      },
    });
    request({
      url: apiPath.getAppSetting,
      method: "GET",
      onSuccess: (data) => {
        setAppSetting(data.data);
        setAppSettingSelected(data.data);
      },
    });
    getNotification();
  }, [refresh]);

  return (
    <>
      <Row gutter={[24, 0]} className="justify-content-between mx-0">
        <Col
          span={24}
          xs={18}
          md={24}
          lg={12}
          sm={5}
          className="SectionMain px-0"
        >
          <div className="toggale-headr">
            <div className="">
              <Button
                type="link"
                className="sidebar-toggler ps-0 d-none d-lg-block"
                onClick={() => setToggle()}
              >
                {toggler}
              </Button>
            </div>
            <div className="d-none d-lg-block">{sectionHeading}</div>
          </div>
          <div className="tabLogo d-sm-block d-lg-none">
            <img className="w-100" src={LogoBlack} />
          </div>
        </Col>
        <Col span={24} xs={24} sm={24} lg={12} className="header-control px-0">
          <Button
            type="link"
            className="sidebar-toggler ps-0"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          {/* <div className="country-wrap">
            <span className="country_icon"></span>
            <Select
              value={country?.country_id}
              options={countries.map((item) => ({
                value: item._id,
                label: item.name,
              }))}
              onChange={(value) => onChange("country_id", value)}
            />
          </div> */}

          <div className="notificationDropdownMain">
            <div className="notification-header d-lg-block">
              <Dropdown
                menu={{ items: notificationitems }}
                trigger={["click"]}
                className="notification-box"
              >
                <Button>
                  <img src={Notification} />
                  <span className="active_notification">{unread}</span>
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="profileDropdownMain">
            <Dropdown
              open={isOpen}
              onOpenChange={(open) => setIsOpen(open)}
              className="edit-box"
              menu={menuProps}
              trigger={["click"]}
            >
              <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open">
                <div className=" d-flex align-items-center gap-2">
                  <div className="userImg">
                    <Image
                      src={profile ? profile.image : notfound}
                      preview={false}
                    />
                    {/* <img src={Avatar} /> */}
                  </div>
                  <div className="d-none d-xl-block">
                    <div className="userName">
                      {profile ? profile?.name : "Administrator"}
                      <DownOutlined />
                    </div>
                  </div>
                </div>
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>

      {isLogoutModalVisible && (
        <DeleteModal
          title={"Logout"}
          subtitle={`Are you sure you want to Logout the Application?`}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => logout()}
        />
      )}

      {profileVisible && (
        <EditProfile
          show={profileVisible}
          hide={() => {
            setProfileVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {appSettingVisible && (
        <AppSetting
          show={appSettingVisible}
          hide={() => {
            setAppSettingVisible(false);
          }}
          data={appSettingSelected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {visible && (
        <ChangePassword
          show={visible}
          handleCreate={handleCreate}
          hide={() => {
            setVisible(false);
          }}
        />
      )}
      {statusVisible && (
        <ChangeStatus
          show={statusVisible}
          data={profile}
          handleCreate={onAvailableStatusChange}
          hide={() => {
            setStatusVisible(false);
          }}
        />
      )}
    </>
  );
}

const EditProfile = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const [doctorDob, setDoctorDob] = useState(
    moment("2009-01-01", "YYYY-MM-DD")
  );
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
      message.error("File format is not correct");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(`Image must be smaller than 5 MB!`);
      return false;
    }
    return true;
  };

  const handleChange = async (event) => {
    const { file } = event;
    setFile([file]);
    uploadFile(file, s3Config("profile"))
      .then((data) => {
        const fileData = {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: data.location,
          thumbUrl: data.location,
        };
        setFile([fileData]);
        setImage(data.location);
        console.log(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({
      ...data,
      dob: data?.dob ? moment(data?.dob, "YYYY-MM-DD") : "",
    });
    //setFile([data.image]);
    setDoctorDob(moment(data?.dob, "YYYY-MM-DD"));

    setMobileNumber({
      mobile_number: data.mobile_number,
      country_code: data.country_code,
    });
    if (data.image != undefined) {
      setImage(data.image);
    } else {
      setImage([notfound]);
    }
  }, [data]);

  const onEditProfile = (values) => {
    const { email, name } = values;
    console.log(file.length <= 0 || !image);
    if (file.length <= 0 && !image)
      return ShowToast("Please select the profile Image ", Severty.ERROR);
    const payload = {};
    setLoading(true);
    payload.email = email;
    payload.name = name;
    payload.image = image;
    request({
      url: apiPath.updateProfile,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      open={show}
      // title={`${data ? "Edit Profile" : ""}`}
      okText="Ok"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      onCancel={hide}
      // okButtonProps={{
      //   form: "create",
      //   htmlType: "submit",
      //   loading: loading,
      //   disabled : true
      // }}
      footer={[
        <Button key="back" onClick={hide}>
          Back
        </Button>,
      ]}
      
    >
      <h4 className="modal_title_cls">Account Information</h4>
      <Form
        id="create"
        form={form}
        onFinish={onEditProfile}
        layout="vertical"
        disabled
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please enter the name!" },
                {
                  pattern: new RegExp(/^[a-zA-Z ]*$/),
                  message: "Only Alphabetic Characters Allowed",
                },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                { required: true, message: "Please enter the email!" },
              ]}
            >
              <Input placeholder="Enter Email Address" disabled />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24} className="flagMobileNumber">
            <Form.Item
              label="Mobile Number"
              // name="mobile_number"
              rules={[
                {
                  required: false,
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject("Please enter phone number");
                    }
                    if (!/^\d{10,15}$/.test(value)) {
                      return Promise.reject(
                        "Phone number must be between 8 and 18 digits"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                inputProps={{
                  name: "mobile_number",
                  required: true,
                  autoFocus: false,
                  autoFormat: false,
                  autoComplete: "off",
                }}
                disabled
                isValid={(value, country) => {
                  if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country?.name;
                  } else if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country?.name;
                  } else if (!/^\d{8,15}$/.test(mobileNumber?.mobile_number)) {
                    return "Phone number must be between 8 and 18 digits";
                  } else {
                    return true;
                  }
                }}
                country={"ca"}
                // preferredCountries={["ps", "il"]}
                value={
                  mobileNumber
                    ? (mobileNumber.country_code
                        ? mobileNumber.country_code
                        : "+27") +
                      (mobileNumber.mobile_number
                        ? mobileNumber.mobile_number
                        : null)
                    : "+27"
                }
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24}>
            <Form.Item
              label="Experience"
              name="experience"
              // rules={[
              //   { required: true, message: "Please enter the experience!" },
              // ]}
            >
              <Input autoComplete="off" placeholder="Enter Expereience" />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24}>
            {/* { console.log( moment(data?.dob).format("YYYY-MM-DD"), "yess>>>")} */}

            <Form.Item
              label="Date of Birth"
              name="dob"
              // rules={[{ required: true, message: "Please enter the DOB!" }]}
            >
              <DatePicker
                format="YYYY-MM-DD" // Corrected the format prop
                defaultPickerValue={doctorDob}
                // disabledDate={(current)=> {return current && current > moment().endOf('day')}}

                disabledDate={(current) => {
                  // Disable dates that are before January 1, 1925 or after December 31, 2009
                  const minDate = moment("1925-01-01", "YYYY-MM-DD");
                  const maxDate = moment("2009-12-31", "YYYY-MM-DD");

                  return (
                    current &&
                    (current.isBefore(minDate) || current.isAfter(maxDate))
                  );
                }}
                // onChange={(date, dateString) => onChange(date, dateString)}
              />
            </Form.Item>
          </Col>
          <Col span={24} lg={24} sm={24}>
            <Form.Item
              label="Gender"
              name="gender"
              // rules={[{ required: true, message: "Please enter the gender!" }]}
            >
              <Select onChange={(value) => form.setFieldValue("gender", value)}>
                <Option value="female">Female</Option>
                <Option value="male">Male</Option>

                <Option value="others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className=""
              label="Upload Profile"
              name="image"
              rules={[
                {
                  required: file.length > 0 ? false : true,
                  message: "Please enter the profile image!",
                },
              ]}
            >
              {/* <Upload
                listType="picture"
                maxCount={1}
                beforeUpload={beforeUpload}
                customRequest={handleChange}
                onRemove={(e) => {
                  setFile([]);
                  setImage();
                }}
                fileList={file}
              >
                {file && file.length > 0 ? null : (
                  <Button icon={<UploadOutlined />}>Upload</Button>
                )}
              </Upload> */}

              <SingleImageUpload
                fileType={FileType}
                imageType={"image"}
                btnName={"Profile Picture"}
                onChange={(data) => setImage(data[0].url)}
              ></SingleImageUpload>

              {image && (
                <div className="mt-3">
                  {" "}
                  <Image width={300} src={image}></Image>{" "}
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const AppSetting = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({ ...data });
  }, [data]);

  const onAppSetting = (values) => {
    setLoading(true);
    request({
      url: apiPath.updateAppSetting,
      method: "POST",
      data: values,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      width={1200}
      open={show}
      // title={`${data ? "Update App Setting" : ""}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <h4 className="modal_title_cls">Update App Setting</h4>
      <Form id="create" form={form} onFinish={onAppSetting} layout="vertical">
        <Row gutter={{ xs: [0, 16], md: [16, 0] }}>
          <Col span={24} md={12}>
            <Card title="Android Details">
              <Col span={24}>
                <Form.Item
                  label="App Store URL"
                  name="app_store_url"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the app store URL!",
                    },
                  ]}
                >
                  <Input placeholder="Enter App Store URL" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Version"
                  name="android_version"
                  rules={[
                    { required: true, message: "Please enter the version!" },
                  ]}
                >
                  <Input placeholder="Enter Android Version" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Share Content"
                  name="android_share_content"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the share content!",
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Share Android Content"
                  />
                </Form.Item>
              </Col>
            </Card>
          </Col>

          <Col span={24} md={12}>
            <Card title="IOS Details">
              <Col span={24} className="">
                <Form.Item
                  label="Play Store URL"
                  name="play_store_url"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the play store URL!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Play Store URL" />
                </Form.Item>
              </Col>

              <Col span={24} className="">
                <Form.Item
                  label="Version"
                  name="ios_version"
                  rules={[
                    { required: true, message: "Please enter the version!" },
                  ]}
                >
                  <Input placeholder="Enter IOS Version" />
                </Form.Item>
              </Col>

              <Col span={24} className="">
                <Form.Item
                  label="Share Content"
                  name="ios_share_content"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the share content!",
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Share IOS Content"
                  />
                </Form.Item>
              </Col>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ChangePassword = ({ show, hide, handleCreate }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={show}
      // title="Change password"
      okText="Ok"
      onCancel={hide}
      //onOk={handleCreate}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        //loading: loading,
      }}
    >
      <h4 className="modal_title_cls">Change Password</h4>
      <Form id="create" form={form} onFinish={handleCreate} layout="vertical">
        <Form.Item
          label="Old Password"
          name="old_password"
          hasFeedback
          rules={[
            { required: true, message: "Please enter the old password!" },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="new_password"
          dependencies={["old_password"]}
          hasFeedback
          rules={[
            { required: true, message: "Please enter the new password!" },
            {
              pattern: new RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
              ),
              message:
                "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("old_password") === value) {
                  return Promise.reject(
                    new Error("Old password & new password must be different!")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="confirm_new_password"
          dependencies={["new_password"]}
          hasFeedback
          rules={[
            { required: true, message: "Please enter the confirm password!" },
            {
              pattern: new RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
              ),
              message:
                "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Confirm password & password does not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ChangeStatus = ({ show, hide, handleCreate, data }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({
      ...data,
    });
  }, [data]);

  return (
    <Modal
      open={show}
      okText="Ok"
      onCancel={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        //loading: loading,
      }}
    >
      <h4 className="modal_title_cls">Change Status</h4>
      <Form id="create" form={form} onFinish={handleCreate} layout="vertical">
        <Form.Item
          // label="Old Password"
          name="availablitiy_status"
          hasFeedback
          rules={[{ required: true, message: "Please select a status" }]}
        >
          <Radio.Group>
            <Radio value={"available"}>Available</Radio>
            <Radio value={"busy"}>Busy</Radio>
            <Radio value={"not available"}>Not-Available</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Header;
