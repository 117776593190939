import { PatientDetail } from "../pages";

let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://inventcolabs.s3.amazonaws.com/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "http://217.15.175.151:9060/api/";
} else {
  // URL = "http://217.15.175.151:9060/api/";
  URL = "https://backend.sugamya-ayurveda.com/api/";
  // URL = "http://localhost:9060/api/";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "/doctor/dashboard/dashboard",
  setting: "/admin/setting",
  listOrder: "admin/order/list",
  viewOrder: "admin/order/view",
  statusOrder: "admin/order/status",
  beforeLogin: "admin/auth/before-login",
  listTransaction: "admin/transaction/list",
  viewTransaction: "admin/transaction/view",
  setting: "admin/setting/",
  // Auth API
  logout: "doctor/auth/logout",
  login: "doctor/auth/login",
  country: "/country",
  doctors: "/doctors",
  slots: "/admin/appointment/slots",
  state: "/state/",
  city: "/city/",
  profile: "doctor/auth/get-profile",
  updateProfile: "doctor/auth/update-profile",
  changePassword: "doctor/auth/change-password",
  availablityStatusChange: "doctor/auth//update-availablitiy-status",
  updateAppSetting: "doctor/auth/update-app-setting",

  // Patient APIs
  addLeave: "/doctor/Leave/addLeave",
  listLeave: "/doctor/Leave/list",
  listUser: "admin/user",
//get case paper api
 casePaper: "/admin/patient",
  // Patient APIs
  listPatient: "/app/user/myPatient",
  listPatientHistory: "/app/user/get-appoint-history",
  patientDetails: "/app/user/getAppointmentDetail",
  drFeedback: "/app/feedback/get-feedback",
  addDrFeedback: "/app/feedback/add-feedback",
  addDocAdvice: "/app/user/add-documentation",
  approveDocument: "/app/user/approve-document",

  viewPatient: "admin/patient/detail",
  viewPatientAppointment: "admin/patient",
  importPatient: "admin/patient/import-file",
  activity: "admin/user-activity",

  // Doctor's APIs
  doctor: "admin/doctor",
  viewDoctor: "admin/doctor/",
  deleteAvailability: "app/doctor/availability-delete",

  forgotPassword: "doctor/auth/forgot-password",
  verifyOTP: "doctor/auth/verify-otp",
  resetPassword: "doctor/auth/reset-password",

  // SubAdmin APIs
  subAdmin: "admin/sub-admin",
  viewSubAdmin: "admin/sub-admin/",
  getModule: "admin/sub-admin/module-list",
  addPermission: "admin/sub-admin/add-permission",

  // ailment manager
  ailment: "admin/ailment",
  ailmentActivity: "admin/user-activity",
  ailmentCategory: "admin/ailment-category",

  // product manager
  product: "admin/product",
  productActivity: "admin/user-activity",
  viewContent: "/admin/content/view",
  policy: "admin/refund/policy/",
  // appointment base price
  appointment: "doctor/appointment/appointment",
  PatientDetail: "doctor/appointment/patient-detail",
  PatientDetailCase: "doctor/dashboard/patientDetail",
  PatientDetailFollowUp: "doctor/dashboard/getFollowUp",
  PatientFollowUp: "app/case/get-followUp",
  CaseDetail: "app/case/get-case",

  PatientHistory: "doctor/appointment/patient-history",
  AppointDetail: "app/user/getAppointmentDetail",
  order: "admin/Order",
  viewAppointment: "doctor/appointment",
  appointmentPrice: "admin/appointment/price",
  getAvailibility: "app/doctor/get-availability",
  addAvailability: "app/doctor/add-availability",
  editAppointment: "app/user/edit-appointment",
  //Address
  address: "admin/user/addAddress",
  deleteAddress: "admin/user/deleteAddress",

  //collector
  collector: "admin/collector",
  location: "admin/service-location",

  // driver APIs
  driver: "admin/driver",
  importDealer: "admin/dealer/import-file",

  discount: "admin/discount",
  revenue: "admin/revenue",

  downloadCase : "/admin/patient/download-pdf",
  banner: "admin/banner",
  history: "admin/delivery-history",
  adminCommon: "admin/common",

  //cart Apis

  getCart: "/app/cart/getCart",
  cartApproval : "/app/cart/approve_status",

  getAppointCart: "/admin/cart/getappointCartList",
  productList: "/admin/cart/products",
  addCart: "/admin/cart/addCart",
  updatecart: "/admin/cart/changeCart",
  updateCartData: "/admin/cart/edit",
  // Content APIs
  // varianceList: "admin/country-variance",
  addVariance: "admin/country-variance",

  // Content APIs
  content: "admin/content",
  notification: "admin/notification",

  // EmailTemplate APIs
  listEmailTemplate: "admin/email-template/list",
  addEditEmailTemplate: "admin/email-template/add-edit",
  statusEmailTemplate: "admin/email-template/status",
  viewEmailTemplate: "admin/email-template/view",

  // Blog APIs
  listBlog: "admin/blog/list",
  addEditBlog: "admin/blog/add-edit",
  statusBlog: "admin/blog/status",
  viewBlog: "admin/blog/view",
  deleteBlog: "admin/blog",
  listReviews: "admin/reviews/list",
  deleteReview: "admin/reviews/delete/",
  statusReview: "admin/reviews/status",

  listTransaction: "admin/trans/list",
  viewTransaction: "admin/trans/view",

  //get case paper api
  addasePaper: "admin/patient",
  addCasePaper: "app/case/add-case",
  addFollowUp: "app/case/add-followUp",
  getCasePaperExist: "app/case/get-CaseBy-patientId/",
  getAddedFollowUp: "app/case/get-followUp/",

  // Banner APIs
  listBanner: "admin/banner/list",
  addBanner: "admin/banner/add",
  editBanner: "admin/banner/edit",
  deleteBanner: "admin/banner",
  statusBanner: "admin/banner/status",
  viewBanner: "admin/banner/view",

  listVideos: "admin/videos/list",
  addVideos: "admin/videos/add",
  editVideos: "admin/videos/",
  deleteVideo: "admin/videos",

  statusVideos: "admin/videos/status",
  viewVideos: "admin/videos/view",
  //category
  listCategory: "admin/category",
  statusCategory: "admin/category/status",

  getAppSetting: "common/app-setting",

  // Size APIs
  size: "admin/size",

  //order
  order: "admin/order",

  //Vendor apis

  common: {
    restaurantCategories: "categories",
    foodCategories: "food-categories",
    countries: "",

    foodItems: "common/food-items",
    countries: "common/",
    cities: "",
    users: "",
  },

  getCountries: "/all-country",
  getProducts: "/products",

  // Auth API
  logout: "doctor/auth/logout",
  signUp: "doctor/auth/sign-up",
};

export default apiPath;
