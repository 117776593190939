import {
  Row,
  Col,
  Card,
  Button,
  Skeleton,
  Avatar,
  Select,
  Image,
  Tooltip,
  Table,
  Tag,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import apiPath from "../../../constants/apiPath";
import { Badge } from "antd";
import moment from "moment";
import notfound from "../../../assets/images/not_found.png";
import { QuoteStatus } from "../../DeliveryHistory/Index";
import ConfirmationBox from "../../../components/ConfirmationBox";
const { Option } = Select;

function View() {
  const sectionName = "Patient";
  const routeName = "patient";
  const params = useParams();
  const navigate = useNavigate();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [patient, setPatient] = useState({});
  const [appointments, setPatientAppointment] = useState([]);
  const [patientAge, setPatientAge] = useState();
  const [patientOrders, setPatientOrder] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([]);
  const { showConfirm } = ConfirmationBox();
  const [refresh, setRefresh] = useState(false);

  const view = (id) => {
    navigate(`/appointment/view/${id}`);
  };
  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewPatient + "/" + id,
      method: "GET",
      onSuccess: ({ status, data }) => {
        if (!status) return;
        setLoading(false);
        setPatient(data);
        setPatientAge(calculateAge(data.dob));
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const fetchAppointmentData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewPatientAppointment + "/appointments/" + id,
      method: "GET",
      onSuccess: ({ status, data }) => {
        if (!status) return;
        setLoading(false);
        setPatientAppointment(data);
        fetchDoctors(data);
        setPatientAge(calculateAge(data.dob));
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const fetchOrderData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewPatientOrders + "/order/" + id,
      method: "GET",
      onSuccess: ({ status, data }) => {
        if (!status) return;
        setLoading(false);
        setPatientOrder(data.docs);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const fetchDoctors = (data) => {
    const uniqueDoctors = {};

    // Iterate through each appointment record
    data.forEach((appointment) => {
      const docotorId = appointment?.doctor_id?._id;
      if (!uniqueDoctors.hasOwnProperty(docotorId)) {
        uniqueDoctors[docotorId] = {
          _id: docotorId,
          uhid: appointment?.doctor_id?.uhid, // Store the entire appointment details
          name: appointment?.doctor_id?.name,
          email: appointment?.doctor_id?.email,
          mobile_number: `${appointment?.doctor_id?.country_code}${appointment?.doctor_id?.mobile_number}`,
        };
      }
    });
    const uniqueDoctorsArray = Object.values(uniqueDoctors);
  };


  const fetchCartData = (id) => {
    request({
      url:
        apiPath.getCart + "/" + id
        ,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data.docs);
          setList(data.docs);
          setPagination((prev) => ({
            current: pagination.current,
            total: data.totalDocs,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData(params.id);
    fetchAppointmentData(params.id);
    fetchOrderData(params.id);
    fetchCartData(params.id)
  }, [params.id,refresh]);

  const AppointmentColumns = [
    {
      title: "Appointment Id",
      key: "appointment_id",
      dataIndex: "appointment_id",
    },
    {
      title: "Scheduled Date",
      key: "date",
      dataIndex: "date",
      render: (_, { appointment_date }) => {
        return appointment_date? moment.parseZone(appointment_date).format("DD-MMM-YYYY"): new Date();
      },

      sorter: (a, b) => moment(a.appointment_date).unix() - moment(b.appointment_date).unix(),
      // defaultSortOrder: 'descend',
    },
    {
      title: "Scheduled Time",
      key: "time",
      dataIndex: "time",
      render: (_, { appointment_time }) => {
        if (!appointment_time) {
          return <p>-</p>;
        }
    
        // Parse the time in UTC and adjust to local time
        const timeInLocal = moment.utc(appointment_time, "HH:mm").local();
    
        // Format the time in local time zone
        return <p>{timeInLocal.isValid() ? timeInLocal.format("hh:mm A") : "-"}</p>;
      },
    },
    {
      title: "Booked On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },

      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
      // defaultSortOrder: 'descend',
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, { price }) => {
        return price ? <span className="cap">${price}</span> : "-";
      },
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_id",
      key: "doctor_id",
      render: (_, { doctor_id }) =>
        // <Image width={50} src={image ? apiPath.assetURL + image : notfound} />
        {
          return (
            <Link
              style={{ color: "blue" }}
              to={`/doctor/view/${doctor_id?._id}`}
            >
              {doctor_id?.name}
            </Link>
          );
        },
    },
    {
      title: "Doctor Email",
      dataIndex: "doctor_id",
      key: "doctor_id",
      render: (_, { doctor_id }) => {
        return doctor_id && doctor_id.email ? (
          <span className="cap">{doctor_id.email}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Appt Type",
      key: "appointment_type",
      dataIndex: "appointment_type",
    },
    {
      title: "Appt Category",
      key: "appointment_category",
      dataIndex: "appointment_category",
    },
    {
      title: "Appt Status",
      key: "appointment_status",
      dataIndex: "appointment_status",
    },
    {
      title: "Doctor Set Status",
      key: "status",
      dataIndex: "status",
    },
  
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip title={"View Details"} color={"purple"} key={"Delete"}>
              <Button
                title=""
                className="btnStyle primary_btn"
                onClick={() => view(record._id)}
              >
                <i class="fa fa-light fa-eye" style={{ fontSize: "14px" }}></i>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  function calculateAge(dob) {
    // Parse the DOB and current date
    const currentDate = new Date();
    const dobDate = new Date(dob);
    const currentDateObj = new Date(currentDate);

    // Calculate the difference in milliseconds
    let ageDiffMs = currentDateObj - dobDate;

    // Convert milliseconds to years, months, and days
    let ageDate = new Date(ageDiffMs);
    let ageYears = Math.abs(ageDate.getUTCFullYear() - 1970);
    let ageMonths = ageDate.getUTCMonth();
    let ageDays = ageDate.getUTCDate() - 1; // Subtract 1 to get days, as getUTCDate returns the day of the month (1 to 31)

    return {
      years: ageYears,
      months: ageMonths,
      days: ageDays,
    };
  }
  const orderColumns = [
    {
      title: "Order id",
      dataIndex: "order_id",
      key: "order_id",
      // render: (_, { uid }) => (uid ? <span className="cap">#{uid}</span> : "-"),
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) =>
        created_at ? (
          <span className="cap">{moment(created_at).format("DD-MM-YYYY")}</span>
        ) : (
          "-"
        ),
    },
    {
      title: "Order Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) =>
        created_at ? (
          <span className="cap">{moment(created_at).format("HH:mm")}</span>
        ) : (
          "-"
        ),
    },
    {
      title: "Appointment ID",
      dataIndex: "appointmentDetails",
      key: "appointment_id",
      filterMultiple: false,
      width: 200,
      render: (_, { appointmentDetails }) => {
        return appointmentDetails && appointmentDetails.appointment_id ? (
          <Link to={`/appointment/view/${appointmentDetails._id}`}>
            {appointmentDetails.appointment_id}
          </Link>
        ) : (
          "-"
        );
      },
      sorter: (a, b) => {
        if (
          a.appointmentDetails &&
          b.appointmentDetails &&
          a.appointmentDetails.appointment_id &&
          b.appointmentDetails.appointment_id
        ) {
          return a.appointmentDetails.appointment_id.localeCompare(
            b.appointmentDetails.appointment_id,
          );
        }
        return 0;
      },
    },
    {
      title: "Appointment Date",
      dataIndex: "appointmentDetails",
      key: "appointment_at",
      render: (_, { appointmentDetails }) => {
        return appointmentDetails ? (
          <span className="cap">
            {moment(appointmentDetails.appointment_at).format("DD-MM-YYYY")}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Appointment Type",
      dataIndex: "appointment_type",
      key: "appointment_type",
      render: (_, { appointmentDetails }) =>
        appointmentDetails ? (
          <span className="cap">{appointmentDetails.appointment_type}</span>
        ) : (
          "-"
        ),
    },
    {
      title: "Products",
      dataIndex: "productDetail",
      key: "products",
      render: (_, { productDetail }) =>
        productDetail.length > 0 ? (
          <ul>
            {productDetail.map((product) => (
              <li key={product._id}>
                {product.name} - {product.quantity} {product.unit}
              </li>
            ))}
          </ul>
        ) : (
          "-"
        ),
    },
    {
      title: "Agency",
      dataIndex: "agency",
      key: "agency",
      render: (_, { agency }) =>
        agency ? <span className="cap">{agency}</span> : "-",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, { userAddress }) =>
        userAddress ? (
          <span className="cap">
            {userAddress.building_no +
              "," +
              userAddress.city?.name +
              "," +
              userAddress.country?.name}
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: "Docket Number",
      dataIndex: "docketNumber",
      key: "docketNumber",
      render: (_, { docketNumber }) =>
        docketNumber ? <span className="cap">{docketNumber}</span> : "-",
    },
    {
      title: "Docket Date",
      dataIndex: "docketDate",
      key: "docketDate",
      render: (_, { docketDate }) =>
        docketDate ? (
          <span className="cap">{moment(docketDate).format("DD-MM-YYYY")}</span>
        ) : (
          "-"
        ),
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
      render: (_, { discountedAmount }) =>
        discountedAmount ? (
          <span className="cap">${discountedAmount}</span>
        ) : (
          "-"
        ),
    },
    {
      title: "Discount Code",
      dataIndex: "discountCode",
      key: "discountCode",
      render: (_, { discountCode }) =>
        discountCode ? <span className="cap">{discountCode}</span> : "-",
    },
    {
      title: "Discounted Amount",
      dataIndex: "discountedAmount",
      key: "discountedAmount",
      render: (_, { discountedAmount, discountedPrice }) =>
        discountedAmount ? (
          <span className="cap">${discountedAmount - discountedPrice}</span>
        ) : (
          "-"
        ),
    },
    {
      title: "Discounted Price",
      dataIndex: "discountedPrice",
      key: "discountedPrice",
      render: (_, { discountedPrice }) =>
        discountedPrice ? <span className="cap">${discountedPrice}</span> : "-",
    },
    {
      title: "Status",
      key: "orderStatus",
      dataIndex: "orderStatus",
      filters: [
        {
          text: "Received",
          value: "Received",
        },
        {
          text: "Shipped",
          value: "Shipped",
        },
        {
          text: "Delivered",
          value: "Delivered",
        },
        {
          text: "Refunded",
          value: "Refunded",
        },
        {
          text: "Cancelled",
          value: "cancelled",
        },
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Ready to pickup",
          value: "ready to pickup",
        },
      ],
      render: (_, { orderStatus, _id }) => {
        return (
          <a>
            {orderStatus}
            {/* <Select
              value={orderStatus}
              style={{ width: 120 }}
              // onChange={(value) => handleChangeStatus(_id, value, "type")}
            >
              <Option value="Received">Received</Option>
              <Option value="Shipped">Shipped</Option>
              <Option value="Delivered">Delivered</Option>
              <Option value="Cancelled">Cancelled</Option>
              <Option value="Refunded">Refunded</Option>
            </Select> */}
          </a>
        );
      },
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (_, { deliveryDate }) =>
        deliveryDate ? (
          <span className="cap">
            {moment(deliveryDate).format("DD-MM-YYYY")}
          </span>
        ) : (
          "-"
        ),
    },
  ];


  const cartColumns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        pagination.current === 1
          ? index + 1
          : (pagination.current - 1) * 10 + (index + 1),
    },
    {
      title: "Cart ID",
      dataIndex: "cartId",
      key: "cartId",
      render: (_, { cartId, patientDetails }) => {
        return cartId ? cartId : cartId;
      },
    },
    {
      title: "Appointment ID",
      dataIndex: "appointmentId",
      key: "appointmentId",
      render: (_, { appointmentDetails }) =>
        appointmentDetails ? appointmentDetails?.[0]?.appointment_id : "-",
    },
    {
      title: "UHID",
      dataIndex: "patientDetails",
      key: "patientDetails",
      render: (_, { patientDetails }) =>
        patientDetails ? (
          patientDetails?.[0]?.uhid
        ) : (
          "-"
        ),
    },
    {
      title: "Patient",
      dataIndex: "patientDetails",
      key: "patientName",
      render: (_, { patientDetails, booked_for }) => {
        return (
          <>
            {patientDetails ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <span className="cap">{patientDetails?.[0]?.name}</span>
                <span className="cap">{patientDetails?.[0]?.email}</span>

                {patientDetails?.[0]?.mobile_number &&
                  patientDetails?.[0]?.country_code && (
                    <span style={{ color: "gray", fontSize: "12px" }}>
                      {patientDetails?.[0]?.country_code +
                        "-" +
                        patientDetails?.[0]?.mobile_number}
                    </span>
                  )}
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Cart Date and Time",
      dataIndex: "cart",
      key: "cart",
      render: (created_at) => {
        return moment(created_at).format("MM-DD-YYYY HH:mm");
      },
    },
    {
      title: "Cart Expiry",
      dataIndex: "cart",
      key: "cart",
      render: (created_at) => {
        return moment(created_at).format("MM-DD-YYYY HH:mm");
      },
    },
  
    // {
    //   title: "User Name",
    //   dataIndex: "userDetails",
    //   key: "userDetails",
    //   render: (userDetails) => userDetails ? userDetails[0]?.name : "-"
    // },
    {
      title: "Products",
      dataIndex: "productDetails",
      key: "products",
      render: (_,{products}) => (
        <>
          {products?.length
            ? products?.map((product,index) => (
                <div key={index} style={{ marginBottom: "8px" }}>
                  <span style={{ marginLeft: "12px" }}>
                    {product?.productDetails?.[0]?.name} {" "} - {product?.qty}
                  </span>
                </div>
              ))
            : "-"}
        </>
      ),
    },
    {
      title: "Cart Basic Price",
      dataIndex: "productDetails",
      key: "productPrice",
      render: (productDetails) => (
        <>
          {productDetails?.map((product, index) => (
            <div key={index} style={{ marginBottom: "8px" }}>
              <span>${product.price}</span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Cart Display Price",
      dataIndex: "productDetails",
      key: "productPrice",
      render: (productDetails) => (
        <>
          {productDetails?.map((product, index) => (
            <div key={index} style={{ marginBottom: "8px" }}>
              <span>${product.price}</span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (duration) => <span>{duration}</span>,
    },
    {
      title: "Cart total",
      dataIndex: "cartTotal",
      key: "cartTotal",
      render: (cartTotal) => (
        <span>{cartTotal ? `$ ${cartTotal?.toFixed(2)}` : "-"}</span>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (total_amount) => (
        <span>{total_amount ? `$ ${total_amount?.toFixed(2)}` : "-"}</span>
      ),
    },
    {
      title: "Admin approval",
      key: "admin_approval",
     
      render: (_, { admin_approval, _id, is_delete }) => {
        let color = admin_approval ? "green" : "red";
        return (
          <a>
            <Tag
             
              color={color}
              key={admin_approval}
            >
              {admin_approval ? "Approved" : "Not-Approved"}
            </Tag>
          </a>
        );
      },
    },
    {
      title: "doctor approval",
      key: "doctor_approval",
     
      render: (_, { doctor_approval, _id, is_delete }) => {
        let color = doctor_approval ? "green" : "red";
        return (
          <a>
            <Tag
              onClick={(e) => {
                !doctor_approval
                ? showConfirm({
                      record: _id,
                      path: apiPath.cartApproval ,
                      onLoading: () => setLoading(true),
                      onSuccess: () => setRefresh((prev) => !prev),
                    })  : message.error("Cart Already approved!");
                 
              }}
              color={color}
              key={doctor_approval}
            >
              {doctor_approval ? "Approved" : "Not-Approved"}
            </Tag>
          </a>
        );
      },
    },
    {
      title: "Cart Status",
      dataIndex: "cartStatus",
      key: "cartStatus",
      render: (cartStatus, record) => (
        <>
          <Tag
            color={
              cartStatus === "checkout"
                ? "green"
                : cartStatus === "expiry"
                ? "red"
                : "orange"
            }
          
           
          >
            {cartStatus}
          </Tag>
        </>
      ),
    },
  
  ];

  const imageUrlPrefix = "https://sugamaya.s3.amazonaws.com/";
  return (
    <>
      <Row gutter={16}>
        <Col span={24} xs={24}>
        <Card title={sectionName + " Details"}>
            {/* <p className="text-right">#{doctor.uhid}</p>
            <p className="text-right">{doctor.name}</p> */}
            {loading ? (
              [1, 2, 3].map((item) => <Skeleton active key={item} />)
            ) : (
              <div className="view-main-list ">
                {loading ? (
                  [1, 2, 3].map((item) => <Skeleton active key={item} />)
                ) : (
                  <div className="view-main-patient">
                    <div className="view-user-prouser-details">
                      <h6>
                        {patient && !patient.image ? (
                          <Avatar
                            style={{
                              backgroundColor: "#00a2ae",
                              verticalAlign: "middle",
                            }}
                            className="cap"
                          >
                            {patient?.name?.charAt(0)}
                          </Avatar>
                        ) : (
                          <Image
                            className="image-radius"
                            src={patient?.image}
                          />
                        )}
                      </h6>

                      <div>
                        <p className="mb-0">#{patient?.uhid}</p>
                        <span> {patient?.name}</span>
                      </div>
                    </div>
                  </div>
                )}
                {/* {
                  <div className="view-inner-cls">
                    <h5>Advisory Note :</h5>
                    {patient && patient?.advisory ? (
                      <h6>
                        <div className="pdf-icons">
                          <i className="fas fa-file-pdf"></i>
                        </div>
                        <h6>
                          <a
                            href={patient?.advisory}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View 
                          </a>
                        </h6>
                      </h6>
                    ) : "-"}
                  </div>
                }
                  {
                  <div className="view-inner-cls">
                    <h5>LabReports Note :</h5>
                    {patient && patient?.labReports ? (
                      <h6>
                        <div className="pdf-icons">
                          <i className="fas fa-file-pdf"></i>
                        </div>
                        <h6>
                          <a
                            href={patient?.labReports}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View 
                          </a>
                        </h6>
                      </h6>
                    ) : "-"}
                  </div>
                } */}

                {patient?.file?.length ? (
                  <div className="view-inner-cls">
                    <h5>Patient Documents:</h5>
                    {patient
                      ? patient?.file?.map((doc) => (
                          <h6>
                            <div className="pdf-icons">
                              {" "}
                              <i class="fas fa-file-pdf"></i>
                            </div>
                            <h6>                          
                              <a
                                href={apiPath.assetURL + doc}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View
                              </a>
                            </h6>
                          </h6>
                        ))
                      : ""}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </Card>
        </Col>

        <div className="float-end mt-3 w-100 text-right">
          <Button
            className="primary_btn btnStyle"
            onClick={() => window.history.back()}
          >
            Back
          </Button>
        </div>
      </Row>

      <Card className="mt-3" title="Appointments">
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs={24} xl={24}>
              <div className="table-responsive customPagination">
                <p>Total Records: {appointments.length}</p>
                <Table
                  loading={loading}
                  columns={AppointmentColumns}
                  dataSource={appointments}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Card>

      <Card className="mt-3" title="Orders">
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs={24} xl={24}>
              <div className="table-responsive customPagination">
                <p>Total Records: {patientOrders.length}</p>
                <Table
                  loading={loading}
                  columns={orderColumns}
                  dataSource={patientOrders}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Card>
      <Card className="mt-3" title="Cart">
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs={24} xl={24}>
              <div className="table-responsive customPagination">
                <p>Total Records: {list?.length}</p>
                <Table
                  loading={loading}
                  columns={cartColumns}
                  dataSource={list}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Card>

      {/* <Card className="mt-3" title="Doctors">
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs={24} xl={24}>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={doctorColumns}
                  dataSource={doctors}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Card> */}
    </>
  );
}

export default View;
