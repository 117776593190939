import React, { useState } from 'react';
import { Form, Input, Button, Upload, Select, Card, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const DietaryPlanAndAdvisoryNotes = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const handleFinish = (values) => {
    console.log('Form values:', values);
    // Handle form submission, e.g., send data to backend
  };

  return (
    <Card title="Dietary Plan and Advisory Notes" style={{ marginTop: 16 }}>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label="Dietary Plan"
              name="dietaryPlan"
              rules={[{ required: true, message: 'Please enter the dietary plan' }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Advisory Notes"
              name="advisoryNotes"
              rules={[{ required: true, message: 'Please enter the advisory notes' }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Upload Documents" name="documents">
              <Upload
                fileList={fileList}
                onChange={handleFileChange}
                beforeUpload={() => false}
                listType="text"
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Patient Ailments"
              name="ailments"
              rules={[{ required: true, message: 'Please select patient ailments' }]}
            >
              <Select mode="multiple" placeholder="Select ailments">
                <Option value="diabetes">Diabetes</Option>
                <Option value="hypertension">Hypertension</Option>
                <Option value="asthma">Asthma</Option>
                {/* Add more options as needed */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default DietaryPlanAndAdvisoryNotes;
