import React, { Suspense, useContext } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { AuthContext, AuthProvider } from "./context/AuthContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/custom.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Loader from "./components/Loader";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Auth/Home";
import Profile from "./pages/Auth/Profile";
import SignIn from "./pages/Auth/SignIn";
import Register from "./pages/Auth/Register";
import Error from "./pages/Error";


import {
  Patient,
  Ailment,
  AppointmentPrice,
  CmsManagement,
  DeliveryHistory,
  Doctor,
  Notifications,
  Order,
  Product,
  Appointment,
  ViewPatient,
  ViewDoctor,
  User, ViewUser,
  Setting,
  // Case,
  PatientDetail,
  DietaryPlanAndAdvisoryNotes,
  Cart,
  Revenue,
  // CasePaper,
  Followup,
  FollowupNew,
  EditAvailability,
  Availabilty,
  ViewAppointment,
  AddCase,
  OldAvailabilty
} from "./pages";

import Activity from "./pages/User/Activity";
import LeaveManagement from "./pages/LeaveManagement/Index";
import PatientHistory from "./pages/User/Patient/History";
import AppointmentDetails from "./pages/User/Patient/Details";
import Chat from "./pages/Chat/Index";
import Case from "./pages/User/Patient/Case";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/sign-up" element={<Register />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />
        {/* <Route exact path="/settings" element={<Setting />} /> */}

        {/* <Route exact path="/permission" element={<Permission/>}/> */}


        {/* Patient Route */}
        <Route exact path="/patient" element={<Patient />} />
        <Route exact path="/patient/history/:id" element={<PatientHistory />} />
        <Route exact path="/patient/details/:id" element={<AppointmentDetails />} />
        <Route exact path="/case/:id" element={<Case/>} />
        <Route exact path="/patient/view/:id" element={<ViewPatient />} />




        <Route exact path="/leave" element={<LeaveManagement />} />
        {/* <Route exact path="/patient-Detail/:id" element={<PatientDetail />} />
        <Route exact path="/patient/view/:id" element={<ViewPatient />} />
        <Route exact path="/user/activity/:id" element={<Activity />} /> */}
        <Route exact path="//chat-support" element={<Chat/>} />

        

        {/* Doctor Route */}
        {/* <Route exact path="/doctor" element={<Doctor />} />
        <Route exact path="/doctor/view/:id" element={<ViewDoctor />} /> */}

        {/* <Route exact path="/user" element={<User />} /> */}
        {/* <Route exact path="/user/view/:id" element={<ViewUser />} /> */}

        <Route exact path="/revenue" element={<Revenue/>}/>
        {/* <Route exact path="/old-availability" element={<OldAvailabilty/>}/> */}
        <Route exact path="/availability" element={<Availabilty/>}/>

        {/* Sub Admin Route */}
        

        {/* Patient Ailment Route */}
        {/* <Route exact path="/ailment" element={<Ailment />} />
        <Route exact path="/ailment/activity/:id" element={<Activity />} />
        
        {/* Product Route */}
        {/* <Route exact path="/product" element={<Product />} />
        <Route exact path="/product/activity/:id" element={<Activity />} /> */}

        {/* appointment ase price manager */}
        {/* <Route exact path="/appointment-price" element={<AppointmentPrice />} /> */} 

        {/* appointment manager */}
        <Route exact path="/appointment" element={<Appointment />} />
        {/* <Route exact path="/appointment/createCase/:id" element={<CasePaper/>}/> */}
        <Route exact path="/appointment/Followup/:id" element={<Followup/>}/>
        <Route exact path="/appointment/Followupnew" element={<FollowupNew/>}/>
        <Route exact path="/appointment/view/:id" element={<ViewAppointment />} />
        <Route exact path="/patient/:id/dietary-plan-and-advisory-notes" element={<DietaryPlanAndAdvisoryNotes />} />
        <Route exact path="/appointment/add_case/:id" element={<AddCase />} />
        

        {/* Order Route */}
        {/* <Route exact path="/order" element={<Order />} /> */}

    
          
        {/* notification */}
        <Route exact path="/notification" element={<Notifications />} />

       
        {/* discount */}
        <Route exact path="/EditAvailability" element={<EditAvailability />} />
       
      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
};

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default App;
