import React, { useState,useEffect } from "react";
import { Modal, Form, Input, Select, Collapse, Button , DatePicker, Card, Row, Col  } from "antd";
import useRequest from "../../hooks/useRequest";
import { Link, useParams,useNavigate } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import CasepaperImg from "../../assets/images/casepaperm-Img.png";
import {
  ArrowLeftOutlined
} from "@ant-design/icons";


const tags = ['Diabetes', 'Diabetes', 'Diabetes', 'Diabetes', 'Diabetes', 'Diabetes'];


const { TextArea } = Input; 

const FollowupNew = ({ show, hide, refresh }) => {



  return (
   

<Card className="mainlogicandfolowuppage">

<Form
      name="follow-up-form"
      layout="vertical"
      
    >
      <Form.Item label="Logic for follow-up" name="logic">
        <TextArea placeholder="enter ..." rows={4} />
      </Form.Item>

     <Row gutter={36}>
<Col span={24} sm={12}>
<Form.Item label="Start time" name="startTime">
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
      </Form.Item>

     
</Col>
<Col span={12} sm={12}>

<Form.Item label="End time" name="endTime">
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
      </Form.Item>
</Col>

     </Row>

      <Form.Item>
      <div className="main-submitbutton000">
      <Button className='ant-btn ant-btn-default btnStyle primary_btn'>
           Submit
          </Button>
      </div>
      </Form.Item>
    </Form>

</Card>


  );
};

export default FollowupNew;
