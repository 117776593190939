import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "./toast";

import moment from "moment";

export const getFileExtension = (url) => {
    // Get the last part of the URL after the last '/'
    const filename = url.substring(url.lastIndexOf('/') + 1);

    // Get the file extension by getting the last part of the filename after the last '.'
    const extension = filename.substring(filename.lastIndexOf('.') + 1);

    return extension;
};

export  const calculateAge = (dob) => {
  console.log("dob????????????????",dob)
  if (!dob) return null;

  const today = moment();
  const birthDate = moment(dob, 'DD-MM-YYYY'); // Specify the format 'DD-MM-YYYY'
  const years = today.diff(birthDate, 'years');
  birthDate.add(years, 'years');
  const months = today.diff(birthDate, 'months');
  birthDate.add(months, 'months');
  const days = today.diff(birthDate, 'days');
  console.log(years,months,days)
  return { years, months, days };
};

export const isObjectEmpty = (obj) => {
    for (const key in obj) {
      if (obj[key]) {

        if(obj[key]==='{"min":0,"max":20000000}'){

        }else{
            return false;
        }
       
      }
    }
    return true;
  }

  export function formatDate(date) {
    const now = moment();
    const inputDate = moment(date);
  
    if (now.isSame(inputDate, 'day')) {
      return 'Today, ' + inputDate.format('hh:mm A');
    } else if (now.subtract(1, 'day').isSame(inputDate, 'day')) {
      return 'Yesterday, ' + inputDate.format('hh:mm A');
    } else {
      return inputDate.format('DD/MM/YYYY, hh:mm A');
    }
  } 

  export function formatPhone(countryCode, phoneNumber) {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    if (countryCode && numericPhoneNumber) {
        const groups = numericPhoneNumber.match(/(\d{2})(\d{3})(\d{3})(\d+)/);
        if (groups) {
            return `+${countryCode}-${groups[1]}-${groups[2]}-${groups[3]}-${groups[4]}`;
        }
    }
    return phoneNumber;
}